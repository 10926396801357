import React from 'react';
import styled from '@emotion/styled';

const MessageWrapper = styled.div`
  background-color: ${props => {
    switch (props.color) {
      case 'blue':
        return props.theme.colors.specialBackground;
      case 'red':
        return props.theme.colors.redMessage;
      case 'purple':
        return props.theme.colors.purpleMessage;
      default:
        return 'transparent';
    }
  }};
  padding: 12px 12px 12px 16px;
  gap: 4px;
  border-radius: ${props => (props.borderColor ? '10px' : '0px')};
  border: ${props => (props.borderColor ? `2px solid ${props.borderColor}` : '0px')};
  display: ${props => (props.display === 'flex' ? 'flex' : 'block')};
  justify-content: ${props => (props.justify === 'justify' ? 'space-between' : 'normal')};
  & svg {
    margin-top: 4px;
  }
`;

export const ColoredMessage = ({ color, borderColor, display, children }) => {
  return (
    <MessageWrapper color={color} borderColor={borderColor} display={display} data-testid={`${color}-colored-message`}>
      {children}
    </MessageWrapper>
  );
};
