import React from 'react';
import { Container, Message, Grid, Flex, Text } from 'basis';
import { calculatedTotalMonthlyExpenses, getStops } from 'utils/expensesFooterHelper';
import { LayoutContent } from 'components';
import { byCountry, isNZ } from 'utils/byConfig';
import { device } from 'media';
import { formatNumber } from 'utils/formatNumber';
import styled from '@emotion/styled';
import { isFeatureOn } from 'featureToggles';

const StickToBottom = styled.div`
  @media ${device.mobileOrSmaller} {
    position: sticky;
    bottom: 0;
    z-index: 999;
  }
`;

const StickOrNot = isNZ() ? styled.div() : StickToBottom;

const BlockSpan = styled.span`
  display: block;
  padding-bottom: 12px;
`;

const PreWrapSpan = styled.span`
  white-space: pre-wrap;
  display: flex;
`;

const InlineFlexSpan = styled.span`
  display: inline-flex;
`;

const ListItem = styled.span`
  display: inline-block;
  padding: 0px 24px 4px 0px;
`;

const BreakBulkPoint = styled.strong`
  padding: 0px 16px;
`;

const BlockStrong = styled.strong`
  display: block;
  padding-top: 12px;
`;

export const ExpensesFooter = ({ values }) => {
  const totalMonthlyExpenses = calculatedTotalMonthlyExpenses(values);
  const stops = isNZ() && getStops(values);

  return (
    <StickOrNot>
      <Container>
        {byCountry({
          NZ: <NZExpenseFooter totalMonthlyExpenses={totalMonthlyExpenses} />,
          AU: <AUExpenseFooter totalMonthlyExpenses={totalMonthlyExpenses} />,
        })}

        {stops && (
          <Container hasBreakpointWidth>
            <LayoutContent>
              {stops.stopType === 'soft' && (
                <Message severity="warning-or-significant" bg="secondary.lightBlue.t25" title={stops?.title}>
                  <BlockSpan>Please review the following details before you continue:</BlockSpan>
                  <span>
                    {stops?.messages?.map((message, key) => {
                      return (
                        <PreWrapSpan key={key}>
                          {'\n'}
                          <InlineFlexSpan>
                            <BreakBulkPoint>•</BreakBulkPoint>
                            <ListItem>{message}</ListItem>
                          </InlineFlexSpan>
                        </PreWrapSpan>
                      );
                    })}
                  </span>
                  <BlockStrong>By continuing you are confirming these are correct</BlockStrong>
                </Message>
              )}

              {stops.stopType === 'hard' && (
                <Message severity="warning-or-significant" bg="secondary.pink.t30" title={stops?.title}>
                  {' '}
                </Message>
              )}
            </LayoutContent>
          </Container>
        )}
      </Container>
    </StickOrNot>
  );
};

const AUExpenseFooter = ({ totalMonthlyExpenses }) => (
  <LayoutContent>
    <Container
      margin="4 0"
      padding="7 4"
      padding-lg="7 6"
      bg="secondary.lightBlue.t25"
      data-testid="expenses-footer-calculator"
    >
      <Grid preset="page" colsGap="0px">
        <Grid.Item colSpan="0-4" colSpan-sm="0-4" colSpan-md="0-6" colSpan-lg="0-6">
          <Flex height="100%" placeItems="center left">
            <Text as="h3" textStyle="heading5" align="left">
              {isFeatureOn('progressBarNudge') ? 'Your total monthly expenses' : 'Your total monthly expense'}
            </Text>
          </Flex>
        </Grid.Item>
        <Grid.Item colSpan="5-8" colSpan-sm="5-8" colSpan-md="7-11" colSpan-lg="7-11">
          <div data-hj-suppress>
            <Text as="h2" textStyle="heading3" align="right" data-hj-suppress>
              ${formatNumber(totalMonthlyExpenses)}
            </Text>
          </div>
        </Grid.Item>
      </Grid>
    </Container>
  </LayoutContent>
);

const NZExpenseFooter = ({ totalMonthlyExpenses }) => (
  <Container margin="4 0" bg="secondary.lightBlue.t25" data-testid="expenses-footer-calculator">
    <Container padding="7 4" hasBreakpointWidth>
      <Grid preset="page" colsGap="0px">
        <Grid.Item colSpan="0-1" colSpan-sm="1-4" colSpan-md="1-3" colSpan-lg="3-6">
          <Flex height="100%" placeItems="center left">
            <Text as="h3" textStyle="heading6" textStyle-md="heading6" align="left">
              Your total monthly expense
            </Text>
          </Flex>
        </Grid.Item>
        <Grid.Item colSpan="2-3" colSpan-sm="5-6" colSpan-md="5-6" colSpan-lg="7-8">
          <div data-hj-suppress>
            <Text as="h2" textStyle="heading4" align="right" data-hj-suppress>
              $ {formatNumber(totalMonthlyExpenses)}
            </Text>
          </div>
        </Grid.Item>
      </Grid>
    </Container>
  </Container>
);
