import React, { useState } from 'react';
import { LayoutPage, LayoutContent, Errors, FormButtons, Form } from 'components';
import { Container, Message, List, Checkbox, Text, Input } from 'basis';
import { submitHandler, getDataLayerElements } from 'utils';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { useEventTracking } from 'react-event-tracker';
import styled from '@emotion/styled';
import {
  useDataLayerAndAnalyticsLinks,
  useSaveApplication,
  useSteps,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
} from 'hooks';
import { numbersOnlyPattern } from '_config/_constants';
import { minCreditLimit, creditLimitIncrement, maxCreditLimit } from '_config/nz/constants';
import { validation } from '_config/_validation/validation';
import { StatementModal } from '../acceptance/StatementModal';

const FORM_ID = 'creditLimitAcceptance';

const validator = validation(
  {
    minValue: minCreditLimit,
    maxValue: maxCreditLimit,
    minLength: 3,
    maxLength: 6,
    incrementValue: creditLimitIncrement,
    validPattern: numbersOnlyPattern,
  },
  {
    required: 'Enter your preferred credit limit first.',
    minValue: `Please enter a credit limit amount of \${{minValue}} or more in \${{incrementValue}} increments.`,
    maxValue: `The maximum amount you can enter is $ ${maxCreditLimit}.`,
    minLength: 'Must have at least {{minLength}} digits.',
    maxLength: 'Must have at most {{maxLength}} digits.',
    incrementValue: `Please enter an amount in \${{incrementValue}} increments.`,
    invalid: 'Please enter a whole dollar amount, without cents.',
  },
)[0];

export const CreditLimitAcceptance = () => {
  const [storeState, updateStore] = useStoreValue();
  const { trackEvent } = useEventTracking();
  const { trackEventSender, getLinkWithAnalytics } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Next');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { goNext, isLastDraftStep } = useSteps();
  const initialValues = useFormInitialValues(FORM_ID);

  const openModal = formData => {
    if (formData.state.values.creditLimit === '') {
      formData.resetForm({
        values: { accepted: false, creditLimit: '' },
        errors: { creditLimit: ['Please tell us your preferred credit limit in dollars.'], accepted: [] },
      });
      return;
    }

    const validateMessage = validator(formData.state.values.creditLimit);
    if (validateMessage) {
      formData.resetForm({
        values: { accepted: false, creditLimit: formData.state.values.creditLimit },
        errors: { creditLimit: [validateMessage], accepted: [] },
      });
      return;
    }

    if (formData.state.values.accepted) {
      return;
    }

    trackEventSender({
      category: 'text-link',
      action: 'interaction',
      location: 'credit-limit-details',
      label: 'I have read and agreed to the Initial Disclosure Statement & Conditions of Use.',
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onChangeCreditLimit = formData => {
    formData.setValues(values => ({
      ...values,
      accepted: false,
    }));
  };

  const onReject = formData => {
    closeModal();
    trackEventSender({
      category: 'text-link',
      action: 'interaction',
      location: 'credit-limit-details',
      label: 'Close',
    });
    formData.setValues(values => ({
      ...values,
      accepted: false,
    }));
  };
  const onAccept = () => {
    closeModal();
    trackEventSender({
      category: 'text-link',
      action: 'interaction',
      location: 'credit-limit-details',
      label: 'I accept',
    });
  };

  const { save, loading } = useSaveApplication({
    storeState,
    onSuccess: goNext,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ values, errors }) => {
    function submit() {
      const newAcquisition = {
        ...storeState.acquisition,
        creditLimitAcceptance: {
          creditLimit: parseInt(values.creditLimit, 10),
          accepted: values.accepted,
        },
      };

      updateStore({
        applicationSubmitted: isLastDraftStep,
        acquisition: newAcquisition,
      });

      const dataLayer = getDataLayerElements({
        ...storeState,
        applicationSubmitted: isLastDraftStep,
        acquisition: newAcquisition,
      });
      const { requestedCreditLimit, approvedCreditLimit, productMaxCreditLimit } = dataLayer.application;

      const creditLimit = [requestedCreditLimit, approvedCreditLimit, productMaxCreditLimit]
        .filter(v => !!v)
        .map(v => parseFloat(v));

      trackEvent({
        event: {
          category: 'application',
          action: isLastDraftStep ? 'application-submitted' : 'application-navigation',
          location: 'credit-limit-details',
          label: 'Next',
          value: Math.min(...creditLimit).toString(),
        },
        ...dataLayer,
      });

      if (isLastDraftStep) {
        save(newAcquisition);
      } else {
        goNext();
      }
    }

    submitHandler({ submit, errors });
  };

  const retrySubmit = () => {
    trackEvent({
      event: {
        category: 'application',
        action: isLastDraftStep ? 'application-submitted' : 'application-navigation',
        location: 'credit-limit-details',
        label: 'Retry',
      },
    });

    save(storeState.acquisition);
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage>
        <Errors applicationRef={storeState.applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id={FORM_ID}
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButtonLabel="Next"
          showSaveDraftButton
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
          hideFormButtons
        >
          {formData => {
            return (
              <>
                <LayoutContent rowsGap="0" noSessionTimeout>
                  <Container margin="6 0 2 0">
                    <Input
                      label="To meet my needs and objectives I would like a credit limit of"
                      variant="numeric"
                      name="creditLimit"
                      key="creditLimit"
                      testId="creditLimit"
                      prefix="$"
                      maxLength={6}
                      validate={validator}
                      validateData={formData.state}
                      onChange={() => onChangeCreditLimit(formData)}
                    />
                  </Container>
                  <Text>
                    This credit limit amount is subject to finalisation of your application and responsible lending
                    requirements.
                  </Text>
                  <Container margin="6 0">
                    <Message margin="8 0 0 0" severity="info-or-minor" bg="secondary.lightBlue.t25">
                      You can always contact us in the future to request a decrease or increase to your credit limit if
                      your circumstances change.
                    </Message>
                  </Container>
                  <Container margin="0 0 4 0">
                    <Checkbox
                      name="accepted"
                      testId="accepted"
                      validate={value => (value === false ? 'You must agree before you continue' : null)}
                      onChange={() => openModal(formData)}
                    >
                      <>
                        By clicking here I confirm that:
                        <Container padding="3 0">
                          <List>
                            <List.Item>
                              I’ve read and agree to{' '}
                              <UnderlineLink textStyle="underline">Conditions of Use </UnderlineLink>
                              and <UnderlineLink textStyle="underline">Initial Disclosure Statement</UnderlineLink>{' '}
                              (read in conjunction with the credit limit noted above).
                            </List.Item>
                            <List.Item>
                              ${formData.state.values.creditLimit === '' ? 0 : formData.state.values.creditLimit} is my
                              preferred credit limit and this meets my needs and objectives.
                            </List.Item>
                            <List.Item>
                              I understand that I may receive a lower credit limit on the completion of the assessment
                              of my application and that my final credit limit will be confirmed to me by email.
                            </List.Item>
                            <List.Item>
                              I understand that I have the right to cancel my credit contract within 10 working days.
                            </List.Item>
                          </List>
                        </Container>
                      </>
                    </Checkbox>
                  </Container>
                </LayoutContent>
                <FormButtons
                  formId={FORM_ID}
                  formData={formData}
                  loading={loading}
                  submitButtonLabel="Next"
                  showBackButton
                />
                <LayoutContent>
                  <Text textStyle="legal" margin="6 0">
                    For your future reference, once your application has been finalised we’ll email you a copy of the
                    Conditions of Use and your Initial Disclosure Statement which will detail your approved credit
                    limit.
                  </Text>
                </LayoutContent>
                <StatementModal
                  isOpen={isModalOpen}
                  onAccept={onAccept}
                  onReject={() => onReject(formData)}
                  applicationDate={storeState.applicationDate}
                  trackEventSender={trackEventSender}
                  getLinkWithAnalytics={getLinkWithAnalytics}
                  creditLimit={formData.state.values.creditLimit}
                />
              </>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};
const UnderlineLink = styled.span`
  color: ${props => props.theme.a.color};
  text-decoration: underline;
  cursor: pointer;
`;
