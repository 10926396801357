import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { isGemAU, setSessionStorage } from 'utils';
import { storageStateKey, useStoreValue } from 'store';
import { STEP_OUTCOME, config } from '_config';
import { defaultsDeep } from 'lodash';
import { getDefaultState } from 'store/initialState';
import { useHistory } from 'react-router-dom';
import { auAcquisitionComplete, nzAcquisitionComplete } from './mockState';
import {
  DECLINED,
  REFERRED,
  PENDING,
  APPROVED,
  SERVICE_UNAVAILABLE,
  POI_CHECK_REQUIRED,
  POI_CHECK_PENDING,
  CONDITIONALLY_APPROVED,
  PROOF_OF_ADDRESS,
  ANTI_MONEY_LAUNDERING,
  PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  BIOMETRICS_CHECK_REQUIRED,
  IDV_CHECK_REQUIRED,
  IDV_CHECK_PENDING,
  CCR_REQUIRED,
  QUOTE_INITIAL,
  QUOTE_DECLINE,
  POITYPE_DOC_UPLOAD,
  POITYPE_PAYSLIP_UPLOAD,
  APOLLO_GRAPHQL_ERROR,
  APOLLO_NETWORK_ERROR,
} from '../store/constants';

import {
  STEP_LOAN_INFORMATION,
  STEP_CONFIRM_CREDIT_LIMIT,
  STEP_DVS_CONSENT,
  STEP_PROCESSING_IDV,
  STEP_EIDV,
  STEP_AUS_POST,
  STEP_VERIFY_INSTORE,
} from '../_config';

export function DevTeleport({ setAllowNavigationToAllRoutes }) {
  const [storeState, updateState] = useStoreValue();
  const history = useHistory();
  const teleport = useCallback(
    async index => {
      setAllowNavigationToAllRoutes();
      const selectedOption = options[config.countryCode][index];
      const state = buildState(selectedOption.getState());
      setSessionStorage(storageStateKey, state);
      updateState(state);
      history.push(selectedOption.path);
    },
    [history, setAllowNavigationToAllRoutes, updateState],
  );

  return (
    <Wrapper>
      {options[config.countryCode].map(({ label, condition, key }, index) =>
        !condition || condition({ storeState, config }) ? (
          <button key={key} onClick={() => teleport(index)} type="button" data-testid={key}>
            {label}
          </button>
        ) : null,
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #d6ebff;

  button {
    padding: 0.5rem 1rem;
    background-color: #d6ebff;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }
`;

const options = {
  AU: [
    {
      label: 'Saved for later',
      key: 'saved-for-later',
      path: '/saved',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Resume - Expired',
      key: 'resume-expired',
      path: '/resume-application-expired',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Commitments',
      key: 'commitments',
      path: '/commitments',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: false,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Additional Card Holder',
      key: 'additional-card-holder',
      path: '/additional-card-holder',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: false,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Processing Submission',
      key: 'processing-submission',
      path: '/processing',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'CCR Loan Information',
      key: 'ccr-loan-information',
      path: STEP_LOAN_INFORMATION,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          otherDetails: {
            ...auAcquisitionComplete.otherDetails,
            residentialStatus: 'MORTGAGE',
          },
        },
        applicationStatus: CCR_REQUIRED,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        undisclosedDebts: [
          {
            id: 1,
            source: 'EQUIFAX_AUSTRALIA',
            category: 'HOME_LOAN',
            creditProvider: 'ANZ',
            currentLimit: 10000.0,
          },
          {
            id: 2,
            source: 'EQUIFAX_AUSTRALIA',
            category: 'HOME_LOAN',
            creditProvider: 'ANZ',
            currentLimit: 20000.0,
          },
          {
            id: 3,
            source: 'EQUIFAX_AUSTRALIA',
            category: 'CREDIT_CARD',
            creditProvider: 'Latitude',
            currentLimit: 3000.0,
          },
        ],
      }),
    },
    {
      label: 'Dynamic Credit Limit - Conditionally Approved',
      key: 'dynamic-credit-limit-conditionally-approved',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
        },
        applicationStatus: QUOTE_INITIAL,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 7000,
      }),
    },
    {
      label: 'Dynamic Credit Limit - Declined',
      key: 'dynamic-credit-limit-declined',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...{
            overview: {
              consentGiven: true,
              hasReadImportantDocuments: true,
            },
            contactDetails: {},
            password: {},
            personalDetails: {},
            otherDetails: {},
            extraDetails: {},
            employmentDetails: {},
            expensesDetails: {},
            additionalDebtsDetails: {},
            confirmCreditLimit: { referred: true },
            preferredCreditLimitDetails: { referred: true },
          },
          confirmReferredCreditLimit: {
            newCreditLimit: '',
          },
        },
        applicationStatus: QUOTE_DECLINE,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
      }),
    },
    {
      label: 'Dynamic Credit Limit - Conditional Approved - Min Limit DJ Premiere',
      key: 'dynamic-credit-limit-conditional-approved-min-limit-dj-premiere',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
        },
        applicationStatus: QUOTE_INITIAL,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 1000,
      }),
    },
    {
      label: 'Dynamic Credit Limit - Conditional Approved - Min Limit DJ Prestige',
      key: 'dynamic-credit-limit-conditional-approved-min-limit-dj-prestige',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
        },
        applicationStatus: QUOTE_INITIAL,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 6000,
      }),
    },
    {
      label: 'Latitude ID - Introduction',
      key: 'latitude-id-introduction',
      path: '/latitude-id-introduction',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        isEmailVerified: false,
        isLatitudeIdPasswordSet: false,
      }),
    },
    {
      label: 'Latitude ID - Password',
      key: 'latitude-id-password',
      path: '/latitude-id',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        isEmailVerified: true,
        isLatitudeIdPasswordSet: false,
      }),
    },
    {
      label: 'Proof of Identity - EIDV - DVS Consent Required',
      key: 'proof-of-identity-eidv-dvs-consent-required',
      path: STEP_DVS_CONSENT,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Proof of Identity - EIDV - Methods',
      key: 'proof-of-identity-eidv-methods',
      path: STEP_EIDV,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
      }),
    },
    {
      label: 'Proof of Identity - EIDV - Pending',
      key: 'proof-of-identity-eidv-pending',
      path: STEP_PROCESSING_IDV,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Proof of Identity - Processing Biometrics',
      key: 'proof-of-identity-processing-biometrics',
      path: '/processing-biometrics',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: BIOMETRICS_CHECK_REQUIRED,
        applicationErrors: null,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
        maskedMobileNumber: '044 *** 234',
      }),
    },
    {
      label: 'Proof of Identity - Australia Post',
      key: 'proof-of-identity-australia-post',
      path: STEP_AUS_POST,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
      }),
    },
    {
      label: 'Proof of Identity - In Store',
      key: 'proof-of-identity-in-store',
      path: STEP_VERIFY_INSTORE,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationRef: '7014625177363',
        applicationErrors: null,
      }),
    },
    {
      label: 'Proof of Identity - In Store - Merchant Apple',
      key: 'proof-of-identity-in-store-merchant-apple',
      condition: () => isGemAU(),
      path: STEP_VERIFY_INSTORE,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationRef: '7014625177363',
        applicationErrors: null,
        applyAndBuy: {
          channel: 'ONLINE',
          merchantId: '700021',
          merchantGroupName: 'Apple',
          merchantName: 'Apple Online',
          shortCode: '489',
          upstreamUrl: 'https://www.apple.com/au/shop/browse/financing',
          storeFinderUrl: 'https://www.latitudefinancial.com.au/interest-free/find-a-store/',
        },
      }),
    },
    {
      label: 'Proof of Income - Methods',
      key: 'proof-of-income-methods',
      path: '/verify-income',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Proof of Income - Methods - Merchant Apple',
      key: 'proof-of-income-methods-merchant-apple',
      condition: () => isGemAU(),
      path: '/verify-income',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
        applyAndBuy: {
          channel: 'ONLINE',
          merchantId: '700021',
          merchantGroupName: 'Apple',
          merchantName: 'Apple Online',
          shortCode: '489',
          upstreamUrl: 'https://www.apple.com/au/shop/browse/financing',
          storeFinderUrl: 'https://www.latitudefinancial.com.au/interest-free/find-a-store/',
        },
      }),
    },
    {
      label: 'Proof of Income - Proviso',
      key: 'proof-of-income-proviso',
      path: '/verify-ficonnect',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Proof of Income - Document Upload',
      key: 'proof-of-income-document-upload',
      path: '/verify-document-upload',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Proof of Income - Document Upload - Timeout',
      key: 'proof-of-income-document-upload-timeout',
      path: '/outcome-timeout',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_PENDING,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Outcome - Approved',
      key: 'outcome-approved',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: 'APPROVED',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        applicationDate: new Date().toISOString(),
        finalDecisionDate: new Date().toISOString(),
        accountNumber: '5218 9435 0015 199',
        creditLimitApproved: 3000,
        applicationRef: '7323655040880',
      }),
    },
    {
      label: 'Outcome - Approved - Merchant Apple',
      key: 'outcome-approved-merchant-apple',
      condition: () => isGemAU(),
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: 'APPROVED',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        applicationDate: new Date().toISOString(),
        finalDecisionDate: new Date().toISOString(),
        accountNumber: '5218 9435 0015 199',
        creditLimitApproved: 3000,
        applicationRef: '7323655040880',
        applyAndBuy: {
          channel: 'ONLINE',
          merchantId: '700021',
          merchantGroupName: 'Apple',
          merchantName: 'Apple Online',
          shortCode: '489',
          upstreamUrl: 'https://www.apple.com/au/shop/browse/financing',
          storeFinderUrl: 'https://www.latitudefinancial.com.au/interest-free/find-a-store/',
        },
      }),
    },
    {
      label: 'Outcome - Declined',
      key: 'outcome-declined',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: DECLINED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Referred',
      key: 'outcome-referred',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Referred - Merchant Apple',
      key: 'outcome-referred-merchant-apple',
      condition: () => isGemAU(),
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        applyAndBuy: {
          channel: 'ONLINE',
          merchantId: '700021',
          merchantGroupName: 'Apple',
          merchantName: 'Apple Online',
          shortCode: '489',
          upstreamUrl: 'https://www.apple.com/au/shop/browse/financing',
          storeFinderUrl: 'https://www.latitudefinancial.com.au/interest-free/find-a-store/',
        },
      }),
    },
    {
      label: 'Outcome - Timeout',
      key: 'outcome-timeout',
      path: '/outcome-timeout',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - System Unavailable',
      key: 'outcome-system-unavailable',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: {
          overview: {
            consentGiven: true,
            hasReadImportantDocuments: true,
          },
          contactDetails: {},
          password: {},
          personalDetails: {},
          otherDetails: {},
          extraDetails: {},
          employmentDetails: {},
          expensesDetails: {},
          additionalDebtsDetails: {},
        },
        applicationSubmitted: true,
        applicationStatus: SERVICE_UNAVAILABLE,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - POA',
      key: 'outcome-conditionally-approved-poa',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - AML',
      key: 'outcome-conditionally-approved-aml',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - POI',
      key: 'outcome-conditionally-approved-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_INCOME,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - POA & POI',
      key: 'outcome-conditionally-approved-poa-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - AML & POI',
      key: 'outcome-conditionally-approved-aml-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Financial Table',
      key: 'financial-table',
      path: `/outcome-fin-table?creditLimit=3,000&accountNumber=5218%209435%200015%20199&applicationDate=2024-07-31T05:54:58.945Z&productCode=${config.productCode}`,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: APPROVED,
        applicationErrors: null,
        applicationDate: new Date().toISOString(),
        accountNumber: '5218 9435 0015 199',
        creditLimitApproved: 3000,
      }),
    },
  ],
  NZ: [
    {
      label: 'Commitments',
      key: 'commitments',
      path: '/commitments',
      getState: () => ({
        acquisition: {
          ...nzAcquisitionComplete,
          acceptance: { accepted: false },
        },
        activeStep: '/commitments',
        applicationStatus: '',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Verify Income',
      key: 'verify-income',
      path: '/verify-income',
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Pending Customer Acceptance',
      key: 'pending-customer-acceptance',
      path: '/acceptance',
      getState: () => ({
        acquisition: {
          ...nzAcquisitionComplete,
          acceptance: { accepted: false },
        },
        applicationSubmitted: true,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 4000,
      }),
    },
    {
      label: 'Outcome - Referred + Upload Payslips',
      key: 'outcome-referred-upload-payslips',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Referred + Upload Documents',
      key: 'outcome-referred-upload-documents',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Doc POA',
      key: 'outcome-conditionally-approved-upload-doc-poa',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Doc AML',
      key: 'outcome-conditionally-approved-upload-doc-aml',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Payslips POI',
      key: 'outcome-conditionally-approved-upload-payslips-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Payslips AML & POI',
      key: 'outcome-conditionally-approved-upload-payslips-aml-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Payslips POA & POI',
      key: 'outcome-conditionally-approved-upload-payslips-poa-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Documents POI',
      key: 'outcome-conditionally-approved-upload-documents-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Documents AML & POI',
      key: 'outcome-conditionally-approved-upload-documents-aml-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Documents POA & POI',
      key: 'outcome-conditionally-approved-upload-documents-poa-poi',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: nzAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },

    {
      label: 'API Error - Address Invalid (no retries)',
      key: 'api-error-address-invalid-no-retries',
      path: '/credit-limit',
      getState: () => ({
        acquisition: {
          ...nzAcquisitionComplete,
          acceptance: { accepted: false },
        },
        applicationStatus: PENDING,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: {
          type: APOLLO_GRAPHQL_ERROR,
          error: {
            graphQLErrors: {
              message: 'address is invalid',
            },
          },
        },
      }),
    },
    {
      label: 'API Error - Bad Gateway (allow retries)',
      key: 'api-error-bad-gateway-allow-retries',
      path: '/credit-limit',
      getState: () => ({
        acquisition: {
          ...nzAcquisitionComplete,
          acceptance: { accepted: false },
        },
        applicationStatus: PENDING,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: {
          type: APOLLO_NETWORK_ERROR,
          error: {
            networkError: {
              bodyText: 'Bad Gateway',
            },
          },
        },
      }),
    },
  ],
};

function buildState(state = {}) {
  const defaultState = getDefaultState();
  return defaultsDeep(state, defaultState);
}
