import React from 'react';
import { Link, Text } from 'basis';
import {
  CONTRACTOR_SELF_EMPLOYED,
  RENTAL_INCOME,
  SELF_EMPLOYED_SUBCONTRACTOR,
  PERMANENT_PART_TIME,
  PERMANENT_FULL_TIME,
  CONTRACTOR_PART_TIME,
  CONTRACTOR_FULL_TIME,
  CASUAL_TEMPORARY,
  CASUAL_SEASONAL_TEMPORARY,
  CONTRACTOR_FIXED_TERM,
  GOVERNMENT_BENEFIT,
  ACC,
  SELF_EMPLOYED,
  PERMANENT,
  INVESTMENT,
  SUPERANNUATION,
  employmentStatusLabels,
  RETIRED,
  WORKERS_COMPENSATION,
  CHILD_SUPPORT,
  GOVERNMENT_BENEFITS,
} from '_config';

const content = {
  AU: {
    payslips: {
      title: 'All documents must contain your full legal name.',
      items: [
        'Is a PDF, screenshot or photo of a full computer-generated payslip',
        'Shows your employer’s name or ACN/ABN',
        'Most recent payment is dated within the last 31 days',
        <>
          Includes <strong>minimum 2 months of year to date</strong> income or{' '}
          <strong>supply 2 consecutive payslips</strong>
        </>,
        'Shows net/gross income and frequency of pay',
        'If employed by a family member we may need an additional document such as a bank statement or accountant letter',
      ],
      acceptableDocs: (
        <Text>
          View our{' '}
          <Link href="https://assets.latitudefinancial.com/proof-of-income/POI-Verification-Table.pdf" newTab>
            list of acceptable proof of income documents.
          </Link>
        </Text>
      ),
    },
    otherDocuments: {
      default: {
        [employmentStatusLabels[RENTAL_INCOME]]: [
          {
            title: 'Bank Statement / Online Transaction History',
            list: [
              'Shows your BSB, account number & account name',
              'Two months of income payments, most recent income showing within the last 31 days',
              'Tenant or rental agency’s name corresponding to the deposits',
            ],
          },
          {
            title: 'Real Estate Rental Statements or Receipts',
            list: [
              'Shows the last 2 months’ rental income',
              'Most recent rental statement or receipt dated within the last 31 days',
              'Shows the Real Estate Agent’s name, ACN /ABN, address and telephone number',
            ],
          },
          {
            title: 'Current Rental Agreement (only for newly signed leases)',
            list: [
              'Is current, formally executed',
              'Shows tenant’s name',
              'Term of rental agreement',
              'Gross rental income',
              'Contracting parties’ signatures',
              'Accompanied with another rental income document if over 4 weeks old',
            ],
          },
        ],
        [employmentStatusLabels[RETIRED]]: [
          {
            title: 'Bank Statement / Online Transaction History',
            list: [
              'Shows your BSB, account number & account name',
              'Three months of income payments, most recent income showing within the last 31 days',
              'If latest payment is not within 31 days provide with another income document',
            ],
          },
          {
            title: 'Tax Office Notice of Assessment',
            list: [
              'Shows Australian Taxation Office details',
              'Is for End of Financial Year date within the last 18 months',
              'Shows the taxable income',
            ],
          },
          {
            title: 'Accountant Letter',
            list: [
              'Shows your accountant’s name, address, ABN/ACN and telephone number',
              'Is dated within the last 31 days',
              'States your income after expenses for the last 3 months',
            ],
          },
          {
            title: 'Tax Return',
            list: [
              'Is prepared by an accountant with accountant details included',
              'End of Financial Year date within the last 18 months,',
              'Includes all pages of the “accountant prepared individual Tax Return”',
            ],
          },
          {
            title: 'Superannuation Statement',
            list: [
              'Shows superannuation provider name, ABN/ACN and address',
              'Issued within the last 45 days',
              'Shows net or gross income',
              'Shows benefit type, amount and frequency',
              'Current balance of the fund',
            ],
          },
          {
            title: 'Letter from Superannuation Fund',
            list: [
              'Shows fund name and ABN/ACN',
              'Issued within the last 31 days',
              'Shows net or gross income',
              'Pay frequency',
            ],
          },
        ],
        [employmentStatusLabels[WORKERS_COMPENSATION]]: [
          {
            title: 'Letter from Insurer',
            list: [
              'Shows name of insurer or relevant body administering payments',
              'Start date of payments',
              'The end date (if applicable), and the amount and frequency of payments',
              'Is dated within the last 31 days',
            ],
          },
        ],
        [employmentStatusLabels[CHILD_SUPPORT]]: [
          {
            title: 'Legal Documentation or Child Support Agency Documentation',
            list: [
              'Is dated within the last 12 months',
              'Accompanied by your bank statement showing 2 months of income payments, most recent',
              'income within the last 31 days',
            ],
          },
        ],
        [employmentStatusLabels[GOVERNMENT_BENEFITS]]: [
          {
            title: 'Bank Statement / Online Transaction History',
            list: [
              'Shows your BSB, account number & account name',
              'Two consecutive income payments, most recent income showing within the last 31 days',
              'Shows deposit amounts and dates',
              'Shows identifiable income transaction, e.g. Centrelink',
            ],
          },
          {
            title: 'Centrelink / Services Australia Statement / Letter',
            list: ['Shows the Agency name', 'Benefit type, amount and frequency', 'Is dated within the last 31 days'],
          },
          {
            title: 'Centrelink/Services Australia Online Payment History',
            list: [
              'Shows the Agency name',
              'Benefit type, amount and frequency',
              'Two consecutive income payments, most recent income showing within the last 31 days',
            ],
          },
          {
            title: 'Veterans Affairs Statement /Letter',
            list: ['Shows income amount and frequency', 'Is on DVA letterhead, dated within the last 31 days'],
          },
        ],
      },
    },
  },
  NZ: {
    payslips: {
      title: 'All documents must contain your full legal name.',
      items: [
        'Is a PDF, screenshot or photo of a full computer-generated payslip',
        'Shows your full name',
        "Your employer's name",
        'Most recent payment is dated within the last 31 days',
        'Includes minimum 2 months of year-to-date income or supply 2 consecutive payslips',
        'Shows either Net or Gross income and frequency of pay',
      ],
      acceptableDocs: (
        <>
          View our{' '}
          <Link href="https://www.gemfinance.co.nz/supporting-documents/" newTab>
            list of acceptable proof of income documents.
          </Link>
        </>
      ),
    },
    otherDocuments: {
      [PERMANENT]: [
        {
          title: 'Bank Statement/Online Transaction History',
          list: [
            'Shows your full name',
            'Your bank, branch, account number & account name',
            'Two months of income payments, most recent income showing within the last 31 days',
            "Employer's name corresponding to the deposits",
          ],
        },
        {
          title: 'Summary of Earnings issued by IRD',
          list: [
            'Is dated within the last 31 days',
            'Is for the previous 12 months',
            'Shows your full name',
            "Your employer's name",
          ],
        },
      ],
      [CASUAL_SEASONAL_TEMPORARY]: [
        {
          title: 'Bank Statement/Online Transaction History',
          list: [
            'Shows your full name',
            'Your bank, branch, account number & account name',
            'Two months of income payments, most recent income showing within the last 31 days',
            "Employer's name corresponding to the deposits",
          ],
        },
        {
          title: 'Summary of Earnings issued by IRD',
          list: [
            'Is dated within the last 31 days',
            'Is for the previous 12 months',
            'Shows your full name',
            "Your employer's name",
          ],
        },
      ],
      [CONTRACTOR_FIXED_TERM]: [
        {
          title: 'Bank Statement/Online Transaction History',
          list: [
            `Shows your full name`,
            `Your bank, branch, account number & account name`,
            `Two months of income payments, most recent income showing within the last 31 days`,
            `Employer's name corresponding to the deposits`,
          ],
        },
        {
          title: 'Summary of Earnings issued by IRD',
          list: [
            `Is dated within the last 31 days`,
            `Is for the previous 12 months`,
            `Shows your full name`,
            `Your employer's name`,
          ],
        },
      ],

      [ACC]: [
        {
          title: 'Letter/Statement from ACC',
          list: [
            'Shows your full name',
            'Start date of payments',
            'The end date (if applicable), and the amount and frequency of payments',
            'Is dated within the last 31 days',
          ],
        },
      ],
      [RENTAL_INCOME]: [
        {
          title: 'Bank Statement/Online Transaction History',
          list: [
            'Shows your full name',
            'Your bank, branch, account number & account name',
            'Two months of income payments, most recent income showing within the last 31 days',
            "Tenant's address or rental agency's name corresponding to the deposits",
          ],
        },
        {
          title: 'Real Estate Rental Statements or Receipts',
          list: [
            'Shows your full name',
            'The last 2 months of rental income',
            'Most recent rental statement or receipt dated within the last 31 days',
            "Shows the real estate agent's name, address and telephone number",
          ],
        },
        {
          title: 'Current Rental Agreement (only for newly signed)',
          list: [
            'Shows your full name',
            'The rental address',
            'Term of the rental agreement',
            'Gross rental income',
            "Contracting parties' signatures",
            'Accompanied with another rental income document if over 4 weeks old',
          ],
        },
      ],
    },
  },
};

[PERMANENT_FULL_TIME, PERMANENT_PART_TIME].forEach(status => {
  content.AU.otherDocuments[status] = [
    {
      title: 'Bank Statement/Online Transaction History',
      list: [
        'Shows your BSB, account number & account name',
        'Two months of income payments, most recent income showing within the last 31 days',
        'Employer’s name or ACN/ABN corresponding to the deposits',
      ],
    },
  ];
});

[CASUAL_TEMPORARY, CONTRACTOR_PART_TIME, CONTRACTOR_FULL_TIME].forEach(status => {
  content.AU.otherDocuments[status] = [
    {
      title: 'Bank Statement/Online Transaction History',
      list: [
        'Shows your BSB, account number & account name',
        'Two months of income payments, most recent income showing within the last 31 days',
        'Employer’s name or ACN/ABN corresponding to the deposits',
      ],
    },
    {
      title: 'ATO Income Statement / Group Certificate',
      list: [
        'Shows your employer’s name or ACN/ABN',
        'Is for the most recent financial tax year, if applying in the following financial year between 1 July and 31 August, and',
        'Supplied with a payslip dated within the last 31 days',
      ],
    },
  ];
});

[SELF_EMPLOYED_SUBCONTRACTOR].forEach(status => {
  content.AU.otherDocuments[status] = [
    {
      title: 'Tax Office Notice of Assessment',
      list: [
        'Shows Australian Taxation Office details',
        'Is for End of Financial Year date within the last 18 months',
        'Shows the taxable income',
      ],
    },
    {
      title: 'Accountant Letter',
      list: [
        'Shows your accountant’s name, address, ABN/ACN and telephone number',
        'Is dated within the last 31 days',
        'States your income after expenses for the last 2 months',
      ],
    },
    {
      title: 'Tax Return',
      list: [
        'Is prepared by an accountant with accountant details included',
        'End of Financial Year date within the last 18 months,',
        'Includes all pages of the “accountant prepared individual Tax Return”',
      ],
    },
    {
      title: 'Profit and Loss Statement',
      list: [
        'Is prepared by an accountant with accountant details included',
        'Shows income period within the last 18 months',
        'Specifies you as a sole trader',
      ],
    },
  ];
});

// Add repeating content for NZ
[SELF_EMPLOYED, CONTRACTOR_SELF_EMPLOYED, INVESTMENT].forEach(status => {
  content.NZ.otherDocuments[status] = [
    {
      title: 'Summary of Earnings issued by IRD',
      list: [
        'Is for the previous 12 months or financial year within the last 18 months',
        'Shows your full name',
        'Your business name (if any)',
      ],
    },
    {
      title: 'Accountant’s Letter',
      list: [
        'Shows your full name',
        "Your accountant's name, address and telephone number",
        'Is dated within the last 31 days',
        'States your Net income after tax and deductions for the last 12 months or financial year within the last 18 months',
      ],
    },
    {
      title: 'Tax Return (IR3)',
      list: [
        'Is prepared by an accountant with accountant’s details included',
        'Shows your full name',
        'End of financial year date within the last 18 months',
        'Includes all pages',
      ],
    },
    {
      title: 'Full Financial Statement',
      list: [
        'Is prepared by an accountant with accountant’s details included',
        'Shows income period within the last 18 months',
        'Your full name',
      ],
    },
  ];
});

[GOVERNMENT_BENEFIT, SUPERANNUATION].forEach(status => {
  content.NZ.otherDocuments[status] = [
    {
      title: 'Bank Statement/Online Transaction History',
      list: [
        'Shows your full name',
        'Your bank, branch, account number & account name',
        'Two consecutive income payments, most recent income showing within the last 31 days',
        'Shows deposit amounts and dates',
        'Shows who made the payment, e.g. WINZ',
      ],
    },
    {
      title: 'Work & Income/IRD Letter or Statement',
      list: [
        'Shows your full name',
        'The government agency’s name',
        'Benefit type, amount and frequency',
        'Is dated within the last 31 days',
      ],
    },
    {
      title: 'Summary of Earnings issued by IRD',
      list: [
        'Is dated within the last 31 days',
        'Is for the previous 12 months',
        'Shows your full name',
        'The government agency’s name',
      ],
    },
  ];
});

export { content };
