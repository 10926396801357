import React, { useState } from 'react';
import { Link, Text } from 'basis';
import styled from '@emotion/styled';
import { ExpenseModal } from './ExpenseModal';

const HelpText = styled.div`
  margin-top: -26px;
  line-height: 20px;
  font-size: 14px;
  p {
    display: inline;
    font-size: 14px;
  }
`;
/**
 * @typedef {{
 *    icon: JSX.Element
 *    title: string,
 *    list: Array.<string>
 *  }} ContentList
 *
 * @typedef {{
 *  title: string
 *  content: string | JSX.Element
 *   items: Array.<ContentList>
 * }} ModalConfig
 *
 * @param {string} id test id identifier.
 * @param {string} text help text.
 * @param {string} hyperLinkText hyperlink text which open the modal.
 * @param {ModalConfig} modal modal content configuration.
 */
export function ExpenseHelpText({ id, text, hyperLinkText, modal }) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <HelpText>
      <Text>{text}</Text>{' '}
      {hyperLinkText && (
        <Link onClick={handleModalClick} newTab={false} testId={`expenses-${id}-link`}>
          {hyperLinkText}
        </Link>
      )}
      {modal && <ExpenseModal isOpen={modalOpen} onClose={closeModal} modal={modal} />}
    </HelpText>
  );
}
