/* eslint-disable camelcase */
import React from 'react';
import { Text, Container, Link } from 'basis';

import HandIcon from 'images/hero-hand.svg';
import SaleIcon from 'images/hero-sale.svg';
import { OutcomeApprovedSources } from '_config/_constants/externalLinks';
import { getTerms } from 'utils';
import { BannerTitleContainer, ImageIcons } from '../Outcome.styles';

const FeatureInformation = ({ trackEventSender, getLinkWithAnalytics }) => {
  const { interest_free, long_term_interest_free } = OutcomeApprovedSources.featureInformation;
  return (
    <Container>
      <Container padding="6" padding-lg="0 6" padding-md="0 14" padding-sm="6">
        <BannerTitleContainer>
          <ImageIcons src={SaleIcon} alt="6 months interest free" />
          <Text textStyle="heading4">6 months Interest Free</Text>
        </BannerTitleContainer>
        <Text textStyle="legal">
          Enjoy more time to pay with 6 months Interest Free* on {getTerms('generalPurchases')} of $250 or more, online
          or instore across the world. Find out{' '}
          <Link
            href={getLinkWithAnalytics({
              location: interest_free.location,
              label: interest_free.label,
              url: interest_free.url,
            })}
            newTab
            onClick={() => trackEventSender({ location: interest_free.location, label: interest_free.label })}
          >
            {interest_free.label}
          </Link>
          .
        </Text>
      </Container>

      <Container padding="6" padding-lg="6" padding-md="6 14">
        <BannerTitleContainer>
          <ImageIcons src={HandIcon} alt="Make it yours, interest free" />
          <Text textStyle="heading4">Get long term interest free – from 10 to 60 months</Text>
        </BannerTitleContainer>
        <Text textStyle="legal">
          Buy now and pay later. Long term interest free deals are available with many of New Zealand’s leading
          retailers, online and in-store.# Ask the retailer for more details. Find out{' '}
          <Link
            href={getLinkWithAnalytics({
              location: long_term_interest_free.location,
              label: long_term_interest_free.label,
              url: long_term_interest_free.url,
            })}
            newTab
            onClick={() =>
              trackEventSender({ location: long_term_interest_free.location, label: long_term_interest_free.label })
            }
          >
            {long_term_interest_free.label}
          </Link>
          .
        </Text>
      </Container>
    </Container>
  );
};

export { FeatureInformation };
