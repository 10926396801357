import { useEffect, useCallback, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { BIOMETRICS_COMPLETED, BIOMETRICS_CANCELLED } from 'store/constants';
import { useHandleApiErrors } from './useHandleApiErrors';

export const LONG_POLL_BIOMETRICS_APPLICATION_GQL = gql`
  query longPollBiometricsApplication($id: ID!) {
    application(id: $id) {
      status
    }
  }
`;

export function useLongPollBiometricsApplication({ applicationId, onSuccess, onError, fetchData }) {
  const [loading, setLoading] = useState(false);
  const { handleError } = useHandleApiErrors('apollo', onError);

  const [query, { data, error, stopPolling, refetch }] = useLazyQuery(LONG_POLL_BIOMETRICS_APPLICATION_GQL, {
    pollInterval: 1000,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (applicationId && fetchData) {
      setLoading(true);
      query({ variables: { id: applicationId } });
    }
  }, [applicationId, fetchData, query]);

  useEffect(() => {
    if (data) {
      const { application } = data;
      if (application.status && [BIOMETRICS_COMPLETED, BIOMETRICS_CANCELLED].includes(application.status)) {
        stopPolling();
        setLoading(false);
        onSuccess && onSuccess(application);
      }
      return;
    }
    if (error) {
      stopPolling();
      setLoading(false);
      handleError(error);
    }
  }, [data, error, stopPolling, onSuccess, handleError]);

  const retry = useCallback(() => {
    setLoading(true);
    refetch();
  }, [refetch]);

  return {
    loading,
    retry,
  };
}
