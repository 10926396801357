import { useState, useCallback } from 'react';
import { isFeatureOn } from 'featureToggles';
import { formatNumber, convertRateToMonthlyNumber } from 'utils';
import { yesNoOptions, numbersOnlyPattern } from '../_constants';

const dollarAmountValidationMessages = {
  required: 'Please provide us your home loan repayments. If not applicable, please enter 0.',
  minLength: 'Must have at least {{minLength}} digits.',
  maxLength: 'Must have at most {{maxLength}} digits.',
  invalid: 'Please enter a whole dollar amount, without cents.',
};

export const additionalDebtsDetails = [
  {
    component: 'RadioGroup',
    name: 'hasOtherDebts',
    label: 'Do you have any credit cards, personal loans or investment property loans?',
    testId: 'has-other-debts',
    options: yesNoOptions,
    showCircles: true,
    validate: {
      messages: {
        required: 'Please provide us if you have other personal loans, credit cards or home loans (investment).',
      },
    },
  },
  {
    condition: values => values.hasOtherDebts === 'yes',
    name: 'hasOtherDebtsYes',
    fields: [
      {
        component: 'Frequency',
        label: () =>
          isFeatureOn('progressBarNudge')
            ? 'Investment property repayments'
            : 'Your share of investment property repayments',
        helpText: () =>
          isFeatureOn('progressBarNudge')
            ? 'Your share of investment property loans NOT including your primary living property expense.'
            : 'Investment property loans NOT including your primary living property expense.',
        name: 'secondaryPropertyMortgageRentBoard',
        testId: 'secondary-property-mortgage-rent-board',
        mode: 'select',
        amountPrefix: '$',
        amountMaxLength: 6,
        quarterly: false,
        validate: {
          props: {
            validPattern: numbersOnlyPattern,
            minLength: 1,
            maxLength: 6,
          },
          messages: {
            ...dollarAmountValidationMessages,
            required: 'Please provide us your home loan repayments. If not applicable, please enter 0.',
          },
          component: 'Frequency',
        },
      },
      {
        component: 'Frequency',
        name: 'loanRepayment',
        label: () =>
          isFeatureOn('progressBarNudge') ? 'Other loan repayments' : 'Your share of other loan repayments',
        helpText: () =>
          isFeatureOn('progressBarNudge')
            ? 'Your share of personal loans or motor loans.'
            : 'Personal loans or motor loans.',
        testId: 'loan-repayment',
        mode: 'select',
        quarterly: false,
        amountPrefix: '$',
        amountMaxLength: 6,
        validate: {
          props: { validPattern: numbersOnlyPattern },
          messages: {
            ...dollarAmountValidationMessages,
            required: 'Please provide us your other loan repayments. If not applicable, please enter 0.',
          },
          component: 'Frequency',
        },
      },
      {
        name: 'totaldebtsGroup',
        horizontal: true,
        fields: [
          {
            component: 'Input',
            label: 'Total credit card limits',
            name: 'totalCardLimits',
            helpText: 'Your total credit limits across all your credit cards',
            testId: 'total-card-limits',
            variant: 'commaNumeric',
            width: '50%',
            prefix: '$',
            validate: {
              props: {
                minLength: 1,
                maxLength: 6,
                validPattern: numbersOnlyPattern,
              },
              messages: {
                ...dollarAmountValidationMessages,
                required: 'Please provide us your total credit limits. If not applicable, please enter 0.',
              },
            },
          },
          {
            component: 'Input',
            label: 'Amount owing on credit card/s',
            name: 'amountOwingOnCreditCard',
            testId: 'amount-owing-on-credit-cards',
            variant: 'commaNumeric',
            prefix: '$',
            width: '50%',
            validate: {
              props: {
                minLength: 1,
                maxLength: 6,
                validPattern: numbersOnlyPattern,
              },
              messages: {
                ...dollarAmountValidationMessages,
                required:
                  'Please provide us your current amount owning on your credit card/s. If not applicable, please enter 0.',
              },
            },
          },
        ],
      },
    ],
  },
];

export const useWarning = () => {
  const [warnings, setWarning] = useState([]);

  const check = useCallback(values => {
    const isHomeLoanTooHigh = Number(values.homeLoans) > 15000;
    const otherLoanRepaymentMonthly = convertRateToMonthlyNumber(values.loanRepayment);
    const isOtherLoanRepaymentTooHigh = otherLoanRepaymentMonthly > 15000;
    const isAmountOwingOnCreditCardTooHigh = Number(values.amountOwingOnCreditCard) > Number(values.totalCardLimits);

    let newWarnings = [];
    if (isHomeLoanTooHigh) {
      newWarnings.push(
        `Please review and confirm your monthly share of home loans is $${formatNumber(values.homeLoans)}.`,
      );
    }
    if (isOtherLoanRepaymentTooHigh) {
      newWarnings.push(
        `Please review and confirm your monthly share of loans is $${formatNumber(otherLoanRepaymentMonthly)}.`,
      );
    }
    if (isAmountOwingOnCreditCardTooHigh) {
      newWarnings.push(
        'Your current credit card debt is more than your total credit limit. Please review and confirm if correct.',
      );
    }
    if (values.hasOtherDebts === 'no') {
      newWarnings = [];
    }
    setWarning(newWarnings);
    return newWarnings.length === 0;
  }, []);

  return { check, warnings };
};
