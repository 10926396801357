import React from 'react';
import { Text, Container, Stack, Message } from 'basis';
import { byCard } from 'utils';
import { DEV_FEATURES } from '__dev';
import { APPLY_BTN_LABEL } from '_config';
import { LandingContentItem } from './LandingPageAu.styles';
import ProfileIcon from '../../images/icons/profile-single.svg';
import HomeIcon from '../../images/icons/home.svg';
import WalletIcon from '../../images/icons/finance-wallet.svg';
import MoneyIcon from '../../images/icons/finance-pay-manual.svg';
import IdentityIcon from '../../images/icons/identity.svg';
import StopwatchIcon from '../../images/icons/stopwatch.svg';
import { StyledButton } from './StartApplicationButton';

export const StartApplication = ({ onSubmit, isAdblockCheckComplete, isAdblockerDetected }) => {
  const items = [
    {
      title: (
        <Container margin-sm="2 0 8 0" margin="6 0">
          <Text textStyle="heading5" as="h4">
            Who can apply?
          </Text>
        </Container>
      ),
      body: (
        <>
          <LandingContentItem>
            <img src={ProfileIcon} alt="profile-icon" />
            <Text>You must be aged 18 or older</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={HomeIcon} alt="house-icon" />
            <Text>
              You must be a resident of Australia or a temporary resident who legally has the right to work in Australia
              (and intends to stay) for at least the next 12 months
            </Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      title: (
        <Container margin-sm="2 0 8 0" margin="6 0">
          <Text textStyle="heading5" as="h4">
            {byCard({
              default: "What you'll need",
            })}
          </Text>
        </Container>
      ),

      body: (
        <>
          <LandingContentItem>
            <img src={MoneyIcon} alt="money-icon" />
            <Text>Income, debts and expenses information</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={IdentityIcon} alt="id-icon" />
            <Text>
              Proof of ID (Australian Driver Licence, Medicare or passport) if you consent to using your ID documents to
              verify your identity
            </Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={WalletIcon} alt="wallet-icon" />
            <Text>Proof of income (such as payslips, bank statements, tax statements)</Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      title: (
        <Container margin-sm="2 0 8 0" margin="6 0">
          <Text textStyle="heading5" as="h4">
            How long will it take?
          </Text>
        </Container>
      ),
      body: (
        <>
          <LandingContentItem>
            <img src={StopwatchIcon} alt="money-icon" />
            <Text>
              You will need 10-15 minutes to complete the application
              <br />
              (If we need additional information from you, we will contact you within 2 business days.)
            </Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      condition: () => isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP,
      content: (
        <Message severity="stop" bg="secondary.pink.t30">
          Please turn off your adblocker for the application to work and refresh your browser once it is done.
        </Message>
      ),
    },
  ];

  return (
    <>
      <Stack gap="2">
        {items.map((item, index) => {
          return (
            <Container margin-md="0 0 6 0" key={`right-product-for-you-${index}`}>
              {item.title && (
                <Container margin-md="0 0 4 0">
                  <Text textStyle="heading5" as="h4">
                    {item.title}
                  </Text>
                </Container>
              )}
              {item.body}
            </Container>
          );
        })}
      </Stack>
      <StyledButton backgroundColor="green" type="submit" data-testid="start-application-button" onClick={onSubmit}>
        <b style={{ color: 'white', fontSize: 18 }}> {APPLY_BTN_LABEL}</b>
      </StyledButton>
    </>
  );
};
