import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, Errors } from 'components';
import { useWaitAndRedirect, useEnv, useCountdownTimer } from 'hooks';
import { Container, Text, LoadingIcon, Flex, Stack } from 'basis';
import { useTheme } from '@emotion/react';
import { useStoreValue } from 'store';
import { isFeatureOn } from 'featureToggles';
import { REFERRED, PROCESSING_TIMEOUT } from 'store/constants';
import { byCountry, isAU } from '../../utils';

const useClearableCountdown = (updateStore, hasError) => {
  const history = useHistory();
  const getEnv = useEnv();

  const redirectToReferred = () => {
    updateStore({
      activeStep: '/outcome',
      applicationStatus: REFERRED,
      applicationStatusReason: PROCESSING_TIMEOUT,
    });
    history.push('/outcome');
  };
  const redirectToTimeout = () => {
    updateStore({
      activeStep: '/outcome-timeout',
      applicationStatusReason: PROCESSING_TIMEOUT,
    });
    history.push('/outcome-timeout');
  };
  const countdown = useCountdownTimer(
    getEnv(
      byCountry({
        NZ: 'REACT_APP_APPLICATION_PROCESS_TIME_IN_SEC',
        AU: 'REACT_APP_AU_APPLICATION_PROCESS_TIME_IN_SEC',
      }),
    ),
    isAU ? redirectToTimeout : redirectToReferred,
  );
  const clearCountdown = countdown[2];

  useEffect(() => {
    hasError && clearCountdown && clearCountdown();
  }, [clearCountdown, hasError]);
};

export const ProcessingSubmission = () => {
  const theme = useTheme();
  const [storeState, updateStore] = useStoreValue();
  const { applicationId, applicationRef, applicationErrors } = storeState;
  const { retry, loading } = useWaitAndRedirect({
    applicationId,
    testId: 'longPollingWhileProcessingSubmission',
  });
  const noProgressStepper = isFeatureOn('progressBarNudge');
  useClearableCountdown(updateStore, !!applicationErrors);

  if (applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }
  const SpinnerContentNZ = () => {
    return (
      <Stack gap="8">
        <Text textStyle="heading4" color={theme.headingColor} align="center">
          Please wait while we process your information.
        </Text>
        <Container textAlign="center">
          <LoadingIcon color={theme.spinnerColor} />
        </Container>
        <Text as="p" textStyle="subtitle2" align="center">
          This typically takes less than 2 minutes.
        </Text>
      </Stack>
    );
  };
  const SpinnerContentAU = () => {
    return (
      <Stack gap="2">
        <Container textAlign="center">
          <LoadingIcon size="large" color={theme.spinnerColor} />
          <Text as="h6" textStyle="heading6" color="black" align="center" margin="8 0 2 0">
            Reviewing your details
          </Text>
          <Text as="p" textStyle="body1" align="center">
            Please hold tight
          </Text>
        </Container>
      </Stack>
    );
  };
  return (
    <LayoutPage noProgressStepper={noProgressStepper}>
      <Container testId="processingSubmission" height="400" padding="6" hasBreakpointWidth>
        <Flex height="100%" placeItems="center">
          {isAU() ? <SpinnerContentAU /> : <SpinnerContentNZ />}
        </Flex>
      </Container>
    </LayoutPage>
  );
};
