import { GOVERNMENT_BENEFITS, WORKERS_COMPENSATION, OTHER, UNEMPLOYED, auGovIndustryOptions } from '_config';
import { isAU } from 'utils/byConfig';

/**
 * handle incompatible form fields migration
 * @param {*} applicationStore store of saved application
 * @returns migrated store
 */
export const migrateSavedApplication = applicationStore => {
  const convertedApplicationStore = applicationStore;
  convertedApplicationStore.employmentDetails = resumeSavedEmploymentDetails(applicationStore.employmentDetails);
  convertedApplicationStore.expensesDetails = resumeSavedExpenseDetails(applicationStore.expensesDetails);
  return convertedApplicationStore;
};

export const resumeSavedEmploymentDetails = savedEmploymentDetails => {
  const convertedEmploymentDetails = savedEmploymentDetails;
  if (!isAU() || savedEmploymentDetails.incomeSource) {
    return convertedEmploymentDetails;
  }
  if (
    savedEmploymentDetails.employmentStatus === GOVERNMENT_BENEFITS &&
    auGovIndustryOptions.find(industry => industry.value === savedEmploymentDetails.industry)
  ) {
    convertedEmploymentDetails.employmentStatus = UNEMPLOYED;
    convertedEmploymentDetails.incomeSource = savedEmploymentDetails.industry;
    return convertedEmploymentDetails;
  }

  if (
    savedEmploymentDetails.employmentStatus === WORKERS_COMPENSATION ||
    savedEmploymentDetails.employmentStatus === UNEMPLOYED ||
    savedEmploymentDetails.employmentStatus === OTHER
  ) {
    convertedEmploymentDetails.incomeSource = savedEmploymentDetails.employmentStatus;
    convertedEmploymentDetails.employmentStatus = UNEMPLOYED;
  }
  return convertedEmploymentDetails;
};

export const resumeSavedExpenseDetails = savedExpenseDetails => {
  const convertedSavedExpenseDetails = savedExpenseDetails;
  if (isAU() && !savedExpenseDetails.isSummaryExpenses) {
    convertedSavedExpenseDetails.isSummaryExpenses = 'no';
  }
  return convertedSavedExpenseDetails;
};
