import styled from '@emotion/styled';

export const TextButton = styled.span`
  color: ${props => props.theme.a.color};
  ${props =>
    props.disabled
      ? `
          cursor: default;
          text-decoration: none;
        `
      : `
          cursor: pointer;
          text-decoration: underline;`}
`;
