import { FiConnectStyle } from 'fi-connect/lib/types';
import { getPath } from 'basis/dist/utils/objectPath';
import { radioGroupTheme } from 'theme/david-jones/radioGroup';
import { checkboxTheme } from 'theme/david-jones/checkbox';
import { linkTheme } from 'theme/david-jones/link';
import { sharedTheme } from '../shared';
import { listTheme } from '../david-jones/list';
import { fieldTheme } from '../twenty-eight-degrees/field';
import { selectTheme } from '../twenty-eight-degrees/select';
import { loadingIconTheme } from '../twenty-eight-degrees/loadingIcon';
import { stepperTheme } from '../twenty-eight-degrees/stepper';

const baseTheme = {
  ...sharedTheme,
  maxWidth: '1220px',
  fiConnectStyle: FiConnectStyle.WHITE_LABEL,
  highlightColor: 'secondary.lightBlue.t25',
  backgroundColor: '#f1f2f3',
  radioColor: 'white',
  checkboxColor: 'white',
  headingColor: '#333',
  primaryButtonColor: '#0061ee',
  newStageButtonColor: '#21A637',
  pinkBackgroundColor: '#FFEBF7',
  darkPinkColor: '#C31B62',
  lightPinkBackgroundColor: '#FDF4F9',
  spinnerColor: 'secondary.blue.t100',
  link: {
    color: '#0061ee',
  },
  colors: {
    ...sharedTheme.colors,
    specialBackground: '#e8f4ff',
    redMessage: '#fae6e7',
    purpleMessage: '#efebff',
    darkRedErrorText: '#b3000b',
    lightRedErrorText: '#d6737a',
    secondary: {
      ...sharedTheme.colors.secondary,
      blue: {
        t100: '#0555c8',
        t80: '#3778d3',
        t60: '#6999de',
        t25: '#66d4f1',
        t15: '#d9e5f8',
      },
    },
  },
};

const helpers = {
  getColor: color => {
    return getPath(baseTheme.colors, color);
  },
  getTextStyle: ({ name, mode }) => {
    switch (mode) {
      case 'self': {
        return baseTheme.textStyles[name];
      }

      case 'self-bold': {
        return {
          ...baseTheme.textStyles[name],
          ...baseTheme.textStyles[`${name}.bold`],
        };
      }

      case 'container': {
        const boldCSS = baseTheme.textStyles[`${name}.bold`];

        return {
          ...baseTheme.textStyles[name],
          ...(boldCSS && {
            '& strong': boldCSS,
            '& b': boldCSS,
          }),
        };
      }

      default: {
        return null;
      }
    }
  },
};

export const auCardsTheme = {
  ...baseTheme,
  list: listTheme(baseTheme, helpers),
  stepper: stepperTheme(baseTheme, helpers),
  field: fieldTheme(baseTheme, helpers),
  radioGroup: radioGroupTheme(baseTheme, helpers),
  checkbox: checkboxTheme(baseTheme, helpers),
  link: linkTheme(baseTheme, helpers),
  loadingIcon: loadingIconTheme(baseTheme, helpers),
  select: selectTheme(baseTheme, helpers),
};
