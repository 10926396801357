import React, { useState, useCallback } from 'react';
import { formatNumber, convertRateToMonthlyNumber } from 'utils';
import { Text, Container, Flex } from 'basis';
import styled from '@emotion/styled';
import { ExpenseHelpText } from 'components/expenses/ExpenseHelpText';
import {
  Utilities,
  Groceries,
  Transport,
  Health,
  Education,
  Connectivity,
  Insurance,
  PriceTag,
  HouseBlue,
  House,
  Recreation,
  NonEssentialInsurance,
  Subscription,
  School,
  DoubleHouse,
  Flight,
  Wallet,
} from 'components/icons';
import {
  yesNoOptions,
  numbersOnlyPattern,
  RS_HOME_OWNER,
  RS_MORTGAGE,
  RS_BOARDING,
  RS_RENT,
  RS_LIVING_WITH_RELATIVE,
  RS_OTHER,
} from '../_constants';

const dollarAmountValidationMessages = {
  required: 'You must enter a dollar amount.',
  invalid: 'Please enter a whole dollar amount, without cents.',
};

const maxLength = 6;

const helpText = {
  basic: {
    text:
      'e.g. utilities, food, transport, health, medical, public education, childcare, essential insurances, clothing, personal care and other housing costs.',
    hyperLinkText: 'What are basic expenses?',
  },
  additional: {
    text:
      'e.g. recreation/entertainment, non-essential insurances, additional superannuation contributions, subscriptions, memberships, private school fees, second dwellings and domestic/international travel.',
    hyperLinkText: 'What are additional expenses?',
  },
  otherHouseCost: {
    text: 'e.g. electricity, gas, water, telephone, internet, streaming subscriptions and ',
    hyperLinkText: 'more about other housing costs.',
  },
};

const modal = {
  basic: {
    title: 'What are basic expenses?',
    content: (
      <>
        <Text>Basic expenses are essential costs that are necessary for living in Australia.</Text>
        <Container margin="4 0">
          <strong>They include:</strong>
        </Container>
      </>
    ),
    items: [
      {
        icon: <Utilities />,
        title: 'Utilities',
        list: ['Electricity', 'Gas', 'Water', 'Other essential services'],
      },
      {
        icon: <Groceries />,
        title: 'Food and groceries',
        list: ['Regular spending on food', 'Household supplies'],
      },
      {
        icon: <Transport />,
        title: 'Transport',
        list: [
          'Costs related to owning and operating a vehicle',
          'Public transport',
          'Other necessary travel expenses',
        ],
      },
      {
        icon: <Health />,
        title: 'Health and medical costs',
        list: ['Doctor visits', 'Medications', 'Other medical expenses'],
      },
      {
        icon: <Education />,
        title: 'Public education and childcare',
        list: ['School fees', 'Childcare costs', 'Related expenses'],
      },
      {
        icon: <Connectivity />,
        title: 'Communication and connectivity',
        list: ['Telephone', 'Internet', 'Other communication services'],
      },
      {
        icon: <Insurance />,
        title: 'Essential insurances',
        list: ['Home', 'Contents', 'Vehicle insurance'],
      },
      {
        icon: <PriceTag />,
        title: 'Clothing and personal care',
        list: ['Basic clothing', 'Personal hygiene products'],
      },
      {
        icon: <House />,
        title: 'Other housing costs',
        list: [
          'Land tax (if applicable)',
          'Council rates',
          'Body corporate fees',
          'Maintenance costs for your primary residence',
        ],
      },
    ],
  },
  additional: {
    title: 'What are additional expenses?',
    content: (
      <>
        <Text>These expenses are not essential, but are part of lifestyle choices.</Text>
        <Container margin="4 0 3 0">
          <strong>They include:</strong>
        </Container>
      </>
    ),
    items: [
      {
        icon: <Recreation />,
        title: 'Recreational and entertainment costs',
        list: ['Spending on hobbies', 'Takeaway / dining out', 'Other leisure activities'],
      },
      {
        icon: <NonEssentialInsurance />,
        title: 'Non-essential insurances',
        list: [
          'Health insurance premiums',
          'Policies such as life',
          'Total and permanent disability (TPD)',
          'Insurance outside of your superannuation fund',
          'Insurance for classic or rare cars',
          'Additional superannuation contributions',
        ],
      },
      {
        icon: <Subscription />,
        title: 'Subscriptions and memberships',
        list: ['Costs for streaming services', 'Other subscriptions', 'Gym memberships'],
      },
      {
        icon: <School />,
        title: 'Private school fees',
        list: ['Education costs for private schooling'],
      },
      {
        icon: <DoubleHouse />,
        title: 'Second dwellings',
        list: ['Expenses related to owning additional property(ies)'],
      },
      {
        icon: <Flight />,
        title: 'Holidays',
        list: ['Domestic travel', 'International/overseas travel'],
      },
    ],
  },
  otherHouseCost: {
    title: 'Other housing costs',
    items: [
      {
        icon: <House />,
        title: 'Other housing costs',
        list: ['Land tax (if applicable)', 'Council rates', 'Domestic travel', 'International/overseas travel'],
      },
      {
        icon: <DoubleHouse />,
        title: 'Second dwellings',
        list: ['Expenses related to owning additional property(ies)'],
      },
    ],
  },
};

const SectionName = styled.div`
  align-self: center;
`;

export const expensesDetails = [
  {
    component: 'CustomComponent',
    children: () => (
      <Flex>
        <Container margin="0 1 0 0">
          <HouseBlue />
        </Container>
        <SectionName>
          <Text textStyle="heading6">Housing expenses</Text>
        </SectionName>
      </Flex>
    ),
  },
  {
    component: 'Frequency',
    name: 'mortgageRentBoard',
    label: 'What is your share of home loan, rent or board?',
    testId: 'mortgage-rent-board',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        invalid: 'Please provide your share of home loan, rent or board. If you have no mortgage, please enter 0.',
        required: 'Please provide your share of home loan, rent or board.',
      },
      component: 'Frequency',
    },
    helpText: "Don't include repayment on investment properties.",
  },
  {
    component: 'CustomComponent',
    children: () => (
      <Flex>
        <Container margin="0 2 0 0">
          <Wallet width="30" height="30" />
        </Container>
        <SectionName>
          <Text textStyle="heading6">Living expenses</Text>
        </SectionName>
      </Flex>
    ),
  },
  {
    name: 'isExpensesShared',
    label: 'Do you share your living expenses?',
    columns: 1,
    color: 'white',
    component: 'RadioGroup',
    options: yesNoOptions,
    showCircles: true,
    testId: 'is-expenses-shared',
    validate: {
      messages: {
        required: 'We need to know if you share your living expenses.',
      },
    },
  },
  {
    component: 'CustomComponent',
    children: () => <ExpenseHelpText id="expenses-shared" text="If you split your living expenses select 'Yes'" />,
  },
  {
    name: 'isSummaryExpenses',
    label: 'Choose one option to enter your living expenses',
    columns: 1,
    color: 'white',
    component: 'RadioGroup',
    options: [
      {
        label: 'Help me calculate by category',
        value: 'no',
      },
      {
        label: 'I know my total living expenses',
        value: 'yes',
      },
    ],
    showCircles: true,
    testId: 'is-summary-expenses',
    validate: {
      messages: {
        required: 'Please select one option to enter your living expenses.',
      },
    },
  },
  {
    condition: values => values.isExpensesShared === 'yes' && values.isSummaryExpenses,
    component: 'Message',
    name: 'sharedExpenseMessage',
    severity: 'info-or-minor',
    bg: 'secondary.lightBlue.t25',
    children: (
      <span>
        Please ensure you enter only your share of expenses. Don’t include your home loan payments, rent or board as we
        have already asked for this.
      </span>
    ),
  },
  {
    condition: values => values.isExpensesShared !== 'yes' && values.isSummaryExpenses,
    component: 'Message',
    name: 'exclusiveExpenseMessage',
    severity: 'info-or-minor',
    bg: 'secondary.lightBlue.t25',
    children: <span>Don’t include your home loan payments, rent or board as we have already asked for this.</span>,
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'Frequency',
    name: 'foodGroceriesLifestyleEntertainment',
    label: values =>
      values.isExpensesShared === 'yes'
        ? 'Your share of food, holidays, recreation and entertainment'
        : 'Food, holidays, recreation and entertainment',
    testId: 'food-groceries-lifestyle-entertainment',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter your food, holidays, recreation and entertainment expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'CustomComponent',
    children: () => (
      <ExpenseHelpText
        id="recreation"
        text="e.g. regular spend on food, household supplies, domestic/international travel, hobbies, dining out and other leisure activities."
      />
    ),
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'Frequency',
    name: 'insurance',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of insurance' : 'Insurance'),
    testId: 'insurance',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter your insurance expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'CustomComponent',
    children: () => (
      <ExpenseHelpText
        id="insurance"
        text="e.g. home, contents, life, health, vehicle, total and permanent disability, additional superannuation contributions."
      />
    ),
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'Frequency',
    name: 'utilities',
    label: values =>
      values.isExpensesShared === 'yes'
        ? 'Your share of utilities and other housing costs'
        : 'Utilities and other housing costs',
    testId: 'utilities',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter your utilities and other housing costs.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'CustomComponent',
    children: () => <ExpenseHelpText id="otherHouseCost" {...helpText.otherHouseCost} modal={modal.otherHouseCost} />,
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'Frequency',
    name: 'educationAndChildcare',
    label: values =>
      values.isExpensesShared === 'yes' ? 'Your share of education and childcare ' : 'Education and childcare ',
    testId: 'education-and-childcare',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter your education and childcare expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'CustomComponent',
    children: () => (
      <ExpenseHelpText
        id="education"
        text="e.g. public and private school fees, childcare costs and related expenses."
      />
    ),
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'Frequency',
    name: 'transport',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of transport' : 'Transport'),
    testId: 'transport',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter your transport expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'CustomComponent',
    children: () => (
      <ExpenseHelpText
        id="transport"
        text="e.g. public transport, registration, servicing, petrol, parking fees and road tolls."
      />
    ),
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'Frequency',
    name: 'personalAndOtherExpense',
    label: values =>
      values.isExpensesShared === 'yes'
        ? 'Your share of clothing, personal care, health and medical'
        : 'Clothing, personal care, health and medical',
    testId: 'personal-and-other-expense',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter your clothing, personal care, health and medical expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'no',
    component: 'CustomComponent',
    children: () => (
      <ExpenseHelpText
        id="personal"
        text="e.g. clothing, personal hygiene products, gym memberships, doctor visits or medications."
      />
    ),
  },
  {
    condition: values => values.isSummaryExpenses === 'yes',
    component: 'Frequency',
    name: 'basicExpenses',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of basic expenses' : 'Basic expenses'),
    testId: 'basic-expenses',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter a value greater than $1 that represents your basic expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'yes',
    component: 'CustomComponent',
    children: () => <ExpenseHelpText id="basic" {...helpText.basic} modal={modal.basic} />,
  },
  {
    condition: values => values.isSummaryExpenses === 'yes',
    component: 'Frequency',
    name: 'additionalExpenses',
    label: values => (values.isExpensesShared === 'yes' ? 'Your share of additional expenses' : 'Additional expenses'),
    testId: 'additional-expenses',
    mode: 'select',
    amountPrefix: '$',
    quarterly: false,
    amountMaxLength: maxLength,
    validate: {
      props: { maxLength, validPattern: numbersOnlyPattern },
      messages: {
        ...dollarAmountValidationMessages,
        required: 'Please enter a value greater than $1 that represents your additional expenses.',
      },
      component: 'Frequency',
    },
  },
  {
    condition: values => values.isSummaryExpenses === 'yes',
    component: 'CustomComponent',
    children: () => <ExpenseHelpText id="additional" {...helpText.additional} modal={modal.additional} />,
  },
];

export const useWarning = () => {
  const [warnings, setWarning] = useState([]);

  const check = useCallback((values, storeState) => {
    const { residentialStatus } = storeState.acquisition.otherDetails;

    const mortgageRentBoardMonthly = convertRateToMonthlyNumber(values.mortgageRentBoard);
    const mortgageRentBoardMonthlyDisplay = formatNumber(mortgageRentBoardMonthly);

    const newWarnings = [];
    if (mortgageRentBoardMonthly === 0 && residentialStatus === RS_RENT) {
      newWarnings.push(
        `Please review and confirm your share of the monthly rent is $${mortgageRentBoardMonthlyDisplay}.`,
      );
    }

    if (mortgageRentBoardMonthly > 15000) {
      switch (residentialStatus) {
        case RS_MORTGAGE:
          newWarnings.push(
            `Please review and confirm your share of the monthly mortgage repayments is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        case RS_HOME_OWNER:
        case RS_OTHER:
        case RS_LIVING_WITH_RELATIVE:
          newWarnings.push(
            `Please review and confirm your share of the monthly housing expenses is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        case RS_BOARDING:
          newWarnings.push(
            `Please review and confirm your share of the monthly board is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        case RS_RENT:
          newWarnings.push(
            `Please review and confirm your share of the monthly rent is $${mortgageRentBoardMonthlyDisplay}.`,
          );
          break;
        default:
          throw new Error(`Unexpected residential status: ${residentialStatus}`);
      }
    }

    setWarning(newWarnings);
    return newWarnings.length === 0;
  }, []);

  return { check, warnings };
};
