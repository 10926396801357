import React from 'react';
import {
  byCard,
  displayCreditLimit,
  getProductInfo,
  canGoBackToMerchant,
  getConditionsOfUseTerm,
  getFinancialTableTerm,
  isGemAUWithMerchantApple,
} from 'utils';
import { Link, Icon } from 'basis';
import {
  config,
  STEP_OUTCOME_FIN_TABLE,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_NZ_GEM,
  CHANNEL_INSTORE,
  CHANNEL_ONLINE,
  OutcomeApprovedSourcesAu,
  PRODUCT_CODE_LOW_RATE,
} from '_config';
import iconShoppingBag from 'images/icon-shopping-bag.svg';
import iconShoppingCart from 'images/icon-shopping-cart.svg';
import iconShop from 'images/icons/shop.svg';
import iconOffer from 'images/icons/offers.svg';
import groupLFramePremiereCardImageMobile from 'images/premiere-card.png';
import groupLFramePrestigeCardImageMobile from 'images/prestige-card.png';
import groupLFrame28DCardImageMobile from 'images/28d-card-vertical.png';
import groupLFrameGoCardImageMobile from 'images/go-card-mobile.png';
import groupLFrameAfsCardImageMobile from 'images/afs-card.png';
import AuGemCardImg from 'images/auGem-card.png';
import LowRateCardImg from 'images/low-rate-card.png';
import { getRatesAndFees } from 'featureToggles';
import { ExternalLinkIcon } from '../../../components/icons';

export function getAuCardOutcomeApprovedNextSteps(storeState, trackEventSender) {
  const { productName, productCode, appleAssistanceNumber } = config;
  const creditLimit = displayCreditLimit(storeState);
  const { applyAndBuy } = storeState;
  const isInStore = applyAndBuy?.channel === CHANNEL_INSTORE;
  const disclosureDate = storeState.finalDecisionDate ?? storeState.applicationDate;
  const finTableParams = `?creditLimit=${creditLimit}&accountNumber=${storeState.accountNumber}&applicationDate=${disclosureDate}&productCode=${productCode}`;
  const finTableUrl = `${STEP_OUTCOME_FIN_TABLE}${finTableParams}`;
  const isMerchantOnline = applyAndBuy?.channel === CHANNEL_ONLINE && canGoBackToMerchant(storeState);
  const inStoreOrMerchant = isInStore || isMerchantOnline;
  const shoppingIcon = byCard({
    [PRODUCT_CODE_AFS]: iconShoppingCart,
    [PRODUCT_CODE_AU_GEM]: inStoreOrMerchant ? iconShoppingCart : iconShoppingBag,
    [PRODUCT_CODE_GO]: inStoreOrMerchant ? iconShoppingCart : iconShoppingBag,
    default: iconShoppingBag,
  });
  const { appleOnlineStore, findAppleStore, merchantList } = OutcomeApprovedSourcesAu;
  const ratesAndFees = getRatesAndFees();
  const conditionsOfUseUrl = getProductInfo().urls?.conditionsOfUse;

  const whatHappensNextStepsAu = byCard({
    default: [
      {
        icon: <Icon name="mail" color="grey.t75" />,
        label: `We've just sent you an email with your important account details. You will receive a welcome pack with your new ${productName} credit card in the mail within 10 business days.`,
      },
      {
        icon: <Icon name="download" color="grey.t75" />,
        label: (
          <>
            We suggest you save a copy of your{' '}
            <Link
              href={finTableUrl}
              newTab
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getFinancialTableTerm(),
                })
              }
            >
              {getFinancialTableTerm()}
            </Link>{' '}
            and{' '}
            <Link
              newTab
              href={conditionsOfUseUrl}
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getConditionsOfUseTerm(),
                })
              }
            >
              {getConditionsOfUseTerm()}
            </Link>
            .
          </>
        ),
      },
    ],
    [PRODUCT_CODE_LOW_RATE]: [
      {
        icon: <Icon name="mail" color="grey.t75" />,
        label: `We've just sent you an email with your important account details. You will receive a welcome pack with your new ${productName} in the mail within 10 business days.`,
      },
      {
        icon: <Icon name="download" color="grey.t75" />,
        label: (
          <>
            We suggest you save a copy of your{' '}
            <Link
              href={finTableUrl}
              newTab
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getFinancialTableTerm(),
                })
              }
            >
              {getFinancialTableTerm()}
            </Link>{' '}
            and{' '}
            <Link
              newTab
              href={conditionsOfUseUrl}
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getConditionsOfUseTerm(),
                })
              }
            >
              {getConditionsOfUseTerm()}
            </Link>
            .
          </>
        ),
      },
    ],
    [PRODUCT_CODE_GO]: [
      {
        icon: <Icon name="mail" color="grey.t75" />,
        label: `We've just sent you an email with your important account details. You will receive a welcome pack with your new ${productName} credit card in the mail within 10 business days.`,
      },
      {
        icon: <img src={iconShop} alt="icon shop" />,
        label: (
          <>
            With {productName} credit card you can shop interest free online and in-store, year-round at Harvey Norman,
            Domayne, Joyce Mayne or several other of{' '}
            <Link
              href={merchantList.url}
              newTab
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: merchantList.label,
                })
              }
            >
              {merchantList.label}
            </Link>
            .
          </>
        ),
      },
      {
        icon: <Icon name="download" color="grey.t75" />,
        label: (
          <>
            We suggest you save a copy of your{' '}
            <Link
              href={finTableUrl}
              newTab
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getFinancialTableTerm(),
                })
              }
            >
              {getFinancialTableTerm()}
            </Link>{' '}
            and{' '}
            <Link
              newTab
              href={conditionsOfUseUrl}
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getConditionsOfUseTerm(),
                })
              }
            >
              {getConditionsOfUseTerm()}
            </Link>
            .
          </>
        ),
      },
    ],
    [PRODUCT_CODE_AU_GEM]: [
      {
        icon: <Icon name="mail" color="grey.t75" />,
        label: `We've just sent you an email with your important account details. You will receive a welcome pack with your new ${productName} credit card in the mail within 10 business days.`,
      },
      {
        icon: <img src={iconOffer} alt="icon offer" />,
        label: (
          <>
            With {productName} credit card, you can enjoy {ratesAndFees?.interestRate}% Interest for 6 months on{' '}
            everyday Visa purchases over ${ratesAndFees?.maximumPurchase2} and up to 55 Days^ on purchases under $
            {ratesAndFees?.maximumPurchase2}.
          </>
        ),
      },
      {
        icon: <Icon name="download" color="grey.t75" />,
        label: (
          <>
            We suggest you save a copy of your{' '}
            <Link
              href={finTableUrl}
              newTab
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getFinancialTableTerm(),
                })
              }
            >
              {getFinancialTableTerm()}
            </Link>{' '}
            and{' '}
            <Link
              newTab
              href={conditionsOfUseUrl}
              onClick={() =>
                trackEventSender({
                  location: 'approval',
                  label: getConditionsOfUseTerm(),
                })
              }
            >
              {getConditionsOfUseTerm()}
            </Link>
            .
          </>
        ),
      },
    ],
  });

  const defaultSteps = [
    {
      title: 'Set up your digital wallet to start shopping straight away',
      list: [
        {
          text: 'Download the Latitude App from the App Store or Google Play.',
        },
        {
          text: 'Log in using your Latitude ID and password.',
        },
        {
          text: 'Add card to your mobile wallet.',
        },
        {
          text: 'Set your card PIN.',
        },
      ],
      showAppDownloadButtons: true,
      showAppleGoogleSamsungPay: false,
    },
    {
      title: 'Make your first purchase',
      list: [
        {
          title: 'In-store:',
          text: 'Tap and pay at the store checkout, using your digital wallet.',
        },
        {
          title: 'Online:',
          text: 'During checkout use Apple Pay, Google Pay or Samsung Pay. Look out for these icons.',
        },
      ],
      showAppDownloadButtons: false,
      showAppleGoogleSamsungPay: true,
    },
  ];

  const davidJonesContent = {
    // show the the "or" separator for variants.
    showSeparator: false,
    boxes: [
      {
        heading: {
          imageSrc: shoppingIcon,
          altText: 'Shopping online or In-store',
          text: 'Start shopping Online & In-store today',
        },
        steps: [
          {
            title: 'Set up your digital wallet to start shopping straight away',
            list: [
              {
                text: 'Download the Latitude App from the App Store or Google Play.',
              },
              {
                text: 'Log in using your Latitude ID and password.',
              },
              {
                text: 'Add card to your mobile wallet.',
              },
              {
                text: 'Set your card PIN.',
              },
            ],
            showAppDownloadButtons: false,
            showAppleGoogleSamsungPay: false,
          },
          {
            title: `Link your ${productName} account to enjoy benefits, including free shipping, gift wrapping and more.`,
            list: [
              {
                text: (
                  <>
                    Log in or create a {productName} account to{' '}
                    <Link
                      newTab
                      href="https://www.davidjones.com/default.aspx?Z=C&action=displaylogin&pr_id=promo_loyalty_1&pr_name=loyalty&pr_creative=signup&pr_position=hero"
                      onClick={() => {
                        trackEventSender({
                          location: 'approval',
                          label: ' link your credit card',
                          action: 'external-link',
                        });
                      }}
                    >
                      link your credit card
                    </Link>{' '}
                    by following the onscreen prompts.
                  </>
                ),
              },
            ],
            showAppDownloadButtons: false,
            showAppleGoogleSamsungPay: false,
          },
          {
            title: 'Make your first purchase',
            list: [
              {
                title: 'In-store:',
                text: 'Tap and pay at the store checkout, using your digital wallet.',
              },
              {
                title: 'Online:',
                text: 'During checkout use Apple Pay, Google Pay or Samsung Pay. Look out for these icons.',
              },
            ],
            showAppDownloadButtons: false,
            showAppleGoogleSamsungPay: true,
          },
        ],
      },
    ],
  };

  const afsContent = {
    // show the the "or" separator for variants.
    showSeparator: false,
    boxes: [
      {
        heading: {
          imageSrc: shoppingIcon,
          altText: 'Shop at Apple',
          text: 'Start shopping at Apple today',
        },
        steps: [
          {
            title: 'You can shop immediately by calling Apple',
            list: [
              {
                text: `Call ${appleAssistanceNumber} (Monday to Friday, 9:00am to 9:00pm and Saturday to Sunday, 9:00am to 6:00pm (AEST).`,
              },
            ],
            showAppDownloadButtons: false,
            showAppleGoogleSamsungPay: false,
            ctaButton: (
              <Link
                href={appleOnlineStore.url}
                appearance="primary-button"
                testId="apple-store-btn"
                width="100%"
                margin="6 0"
                onClick={() => {
                  trackEventSender({
                    location: appleOnlineStore.location,
                    label: appleOnlineStore.label,
                    action: 'external-link',
                  });
                }}
                newTab
              >
                {appleOnlineStore.label} <ExternalLinkIcon style={{ marginLeft: '8px' }} />
              </Link>
            ),
          },
          {
            title: 'To make a purchase at an Apple Retail Store',
            subTitle: 'Visit your nearest Apple Store with your:',
            list: [
              {
                text: 'Photo ID',
              },
              {
                text: (
                  <>
                    Account number: <strong>{storeState.accountNumber}</strong>
                  </>
                ),
              },
              {
                text: 'And keep your mobile phone handy',
              },
            ],
            showAppDownloadButtons: false,
            showAppleGoogleSamsungPay: false,
            ctaButton: (
              <Link
                href={findAppleStore.url}
                appearance="secondary-button"
                testId="apple-find-a-store-btn"
                width="100%"
                margin="6 0"
                onClick={() => {
                  trackEventSender({
                    location: findAppleStore.location,
                    label: findAppleStore.label,
                    action: 'external-link',
                  });
                }}
                newTab
              >
                {findAppleStore.label} <ExternalLinkIcon style={{ marginLeft: '8px' }} />
              </Link>
            ),
          },
        ],
      },
      {
        heading: {
          imageSrc: iconShoppingBag,
          altText: 'Manage your account',
          text: 'Manage your account',
        },
        steps: [
          {
            title: 'Access the Latitude App to manage your account today',
            list: [
              {
                text: 'Download the Latitude App from the App Store or Google Play.',
              },
              {
                text: 'Log in using your Latitude ID and password.',
              },
            ],
            showAppDownloadButtons: true,
            showAppleGoogleSamsungPay: false,
          },
        ],
      },
    ],
  };

  const defaultContent = {
    showSeparator: false,
    boxes: [
      {
        heading: {
          imageSrc: shoppingIcon,
          altText: 'Shopping online or In-store',
          text: 'Start shopping Online & In-store today',
        },
        steps: defaultSteps,
      },
    ],
  };

  const gemMerchantAppleCallList = [
    {
      text: `Call ${appleAssistanceNumber} (Monday to Friday, 9:00am to 9:00pm and Saturday to Sunday, 9:00am to 6:00pm (AEST).`,
    },
  ];

  const goGemStepOnlinePurchaseList = [
    {
      text: 'Checkout using your:',
    },
    {
      text: (
        <>
          Account number: <strong>{storeState.accountNumber}</strong>
        </>
      ),
    },
    { text: 'Mobile phone on hand for verification' },
  ];

  const goGemStepInStorePurchaseList = [
    {
      text: 'Photo ID',
    },
    {
      text: (
        <>
          Account number: <strong>{storeState.accountNumber}</strong>
        </>
      ),
    },
    { text: 'And keep your mobile phone handy' },
  ];

  const goGemMerchantOnlineAndInstoreContent = {
    showSeparator: isGemAUWithMerchantApple(storeState) ? false : inStoreOrMerchant,
    boxes: [
      {
        heading: {
          imageSrc: shoppingIcon,
          altText: 'Shopping cart icon',
          text: isInStore
            ? 'Start shopping In-store today'
            : isGemAUWithMerchantApple(storeState)
            ? 'Start shopping at Apple today'
            : 'Start shopping Online & In-store today',
        },
        steps: [
          {
            title: isInStore
              ? 'To make a purchase in-store'
              : isGemAUWithMerchantApple(storeState)
              ? 'You can shop immediately by calling Apple'
              : 'For online purchases',
            subTitle: isInStore ? 'Talk with a store assistant and present your:' : '',
            list: isInStore
              ? goGemStepInStorePurchaseList
              : isGemAUWithMerchantApple(storeState)
              ? gemMerchantAppleCallList
              : goGemStepOnlinePurchaseList,
            showAppDownloadButtons: false,
            showAppleGoogleSamsungPay: false,
            ctaButton: isMerchantOnline && (
              <Link
                href={applyAndBuy?.upstreamUrl}
                appearance="primary-button"
                testId="back-to-merchant-btn"
                width="100%"
                margin="6 0"
                onClick={() => {
                  trackEventSender({
                    location: 'outcome',
                    label: `Back to ${applyAndBuy?.merchantGroupName}`,
                    action: 'external-link',
                  });
                }}
                newTab
              >
                {isGemAUWithMerchantApple(storeState)
                  ? 'Apple Online Store'
                  : `Back to ${applyAndBuy?.merchantGroupName}`}
                <ExternalLinkIcon style={{ marginLeft: '8px' }} />
              </Link>
            ),
          },
        ],
      },
      {
        heading: {
          imageSrc: iconShoppingBag,
          altText: 'bag icon',
          text: 'Set up your digital wallet',
        },
        steps: defaultSteps,
      },
    ],
  };

  // For Gem AU with Merchant Apple or GO Mastercard when referred by merchant and channel is ONLINE,
  // add in-store instructions as step 2.
  if (isGemAUWithMerchantApple(storeState) || (productCode === PRODUCT_CODE_GO && isMerchantOnline)) {
    goGemMerchantOnlineAndInstoreContent.boxes[0].steps.push({
      title: isGemAUWithMerchantApple(storeState)
        ? 'To make a purchase at an Apple Retail Store'
        : 'To make a purchase in-store',
      subTitle: `Visit your nearest ${
        isGemAUWithMerchantApple(storeState)
          ? 'Apple Store'
          : "Harvey Norman, Domayne, Joyce Maine or several other Australia's leading retailers"
      } with your:`,
      list: goGemStepInStorePurchaseList,
      showAppDownloadButtons: false,
      showAppleGoogleSamsungPay: false,
      ctaButton: (
        <Link
          /** Go Mastercard always use Latitude's Find a Store link for outcome page */
          href="https://www.latitudefinancial.com.au/interest-free/find-a-store/"
          appearance="secondary-button"
          testId="find-a-store-btn"
          width="100%"
          margin="6 0"
          onClick={() => {
            trackEventSender({
              location: 'outcome',
              label: 'Find a store',
              action: 'external-link',
            });
          }}
          newTab
        >
          Find a store <ExternalLinkIcon style={{ marginLeft: '8px' }} />
        </Link>
      ),
    });
  }

  const shopOnlineAndInStoreAu = byCard({
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: davidJonesContent,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: davidJonesContent,
    [PRODUCT_CODE_AFS]: afsContent,
    [PRODUCT_CODE_AU_GEM]: inStoreOrMerchant ? goGemMerchantOnlineAndInstoreContent : defaultContent,
    [PRODUCT_CODE_GO]: inStoreOrMerchant ? goGemMerchantOnlineAndInstoreContent : defaultContent,
    default: defaultContent,
  });

  return { whatHappensNextStepsAu, shopOnlineAndInStoreAu };
}

export function getCardImageMobile() {
  return byCard({
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: groupLFramePrestigeCardImageMobile,
    [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: groupLFrame28DCardImageMobile,
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: groupLFramePremiereCardImageMobile,
    [PRODUCT_CODE_GO]: groupLFrameGoCardImageMobile,
    [PRODUCT_CODE_AU_GEM]: AuGemCardImg,
    [PRODUCT_CODE_AFS]: groupLFrameAfsCardImageMobile,
    [PRODUCT_CODE_NZ_GEM]: groupLFramePremiereCardImageMobile,
    [PRODUCT_CODE_LOW_RATE]: LowRateCardImg,
    default: null,
  });
}
