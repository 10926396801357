import { isFeatureOn } from 'featureToggles';

export const auAcquisitionComplete = makeAuMockState().acquisitionComplete;
export const nzAcquisitionComplete = makeNzMockState().acquisitionComplete;

function makeAuMockState() {
  const latitudeId = {
    latitudeId: 'mock@email.com',
    password: '12Abc$^&',
  };

  const password = {
    password: 'Abc123',
  };

  const otherDetails = {
    residentialStatus: 'HOME_OWNER',
    lengthOfCurrentResidency: {
      years: '3',
      months: '0',
    },
    isPostalSameWithResidential: 'yes',
    hasNonDomesticPreviousAddress: false,
    currentAddress: {
      useManualAddress: false,
      address: {
        addressType: 'STREET_ADDRESS',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '800',
        streetName: 'Collins',
        streetType: 'Road',
        suburb: 'Docklands',
        state: 'VIC',
        postcode: '3008',
        countryCode: 'AU',
      },
    },
    previousAddress: {
      useManualAddress: false,
      address: {
        addressType: '',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        state: '',
        postcode: '',
        countryCode: 'AU',
      },
    },
    postalAddress: {
      useManualAddress: false,
      address: {
        addressType: '',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        streetType: '',
        suburb: '',
        state: '',
        postcode: '',
        countryCode: 'AU',
      },
    },
  };

  const extraDetails = {
    idType: 'AU_DRIVERS_LICENCE',
    licenceNumber: '123123',
    version: '453',
    expiryDate: {
      day: '01',
      month: '01',
      year: '2030',
    },
    australianState: 'VIC',
  };

  const employmentDetails = {
    industry: 'COMPUTER_STAFF',
    employerName: 'Latitude',
    lengthOfTimeAtCurrentEmployer: {
      years: '1',
      months: '0',
    },
    lengthOfUnemployment: {
      years: '',
      months: '',
    },
    income: {
      amount: '14000',
      frequency: 'monthly',
    },
    employmentStatus: 'PERMANENT_FULL_TIME',
    hasOtherIncome: 'yes',
    otherEmploymentStatus: 'CASUAL_TEMPORARY',
    otherIncome: {
      amount: '1000',
      frequency: 'monthly',
    },
    expectedIncomeChange: 'no',
    incomeAdjustmentRequired: 'no',
    conditionalStop: 'no',
  };

  const expensesDetails = {
    isExpensesShared: 'no',
    isSummaryExpenses: 'no',
    mortgageRentBoard: {
      amount: '1000',
      frequency: 'monthly',
    },
    foodGroceriesLifestyleEntertainment: {
      amount: '400',
      frequency: 'monthly',
    },
    insurance: {
      amount: '300',
      frequency: 'monthly',
    },
    utilities: {
      amount: '400',
      frequency: 'monthly',
    },
    educationAndChildcare: {
      amount: '500',
      frequency: 'monthly',
    },
    transport: {
      amount: '600',
      frequency: 'monthly',
    },
    personalAndOtherExpense: {
      amount: '700',
      frequency: 'monthly',
    },
    basicExpenses: {
      amount: '',
      frequency: 'monthly',
    },
    additionalExpenses: {
      amount: '',
      frequency: 'monthly',
    },
  };

  const additionalDebtsDetails = {
    hasOtherDebts: 'no',
    secondaryPropertyMortgageRentBoard: {
      amount: '',
      frequency: 'monthly',
    },
    totalCardLimits: '',
    amountOwingOnCreditCard: '',
    loanRepayment: {
      amount: '',
      frequency: 'monthly',
    },
    hasLoanLumpSumPayment: 'no',
    payLumpSumWithExistingSaving: '',
  };

  const confirmCreditLimit = {
    empty: {
      newCreditLimit: '',
      creditLimitOptions: null,
    },
    valid: {
      newCreditLimit: '',
      creditLimitOptions: 'acceptCreditLimit',
    },
    declined: {
      creditLimit: '',
    },
    referred: {
      creditLimit: '',
    },
    conditionallyApproved: {
      creditLimit: '',
    },
  };

  const preferredCreditLimitDetails = {
    valid: {
      creditLimit: '5000',
    },
    declined: {
      creditLimit: '2000',
    },
    referred: {
      creditLimit: '2000',
    },
    conditionallyApproved: {
      creditLimit: '2000',
    },
  };

  const acceptance = {
    accepted: true,
  };

  const achDetails = {
    hasAch: 'yes',
    sameCurrentAddress: 'no',
    personalDetails: {
      title: 'MRS',
      firstName: 'Rachel',
      middleName: 'R',
      lastName: 'Madams',
      dateOfBirth: {
        day: '1',
        month: '8',
        year: '1975',
      },
      hasMiddleName: 'yes',
      mobileNumber: '0400000001',
      emailAddress: 'mock@email.com',
    },
    currentAddress: {
      useManualAddress: false,
      address: {
        addressType: 'STREET_ADDRESS',
        poBoxNumber: '',
        propertyName: '',
        unitNumber: '',
        streetNumber: '801',
        streetName: 'Collins',
        streetType: 'Road',
        suburb: 'Docklands',
        state: 'VIC',
        postcode: '3008',
        countryCode: 'AU',
      },
    },
  };

  const contactDetails = {
    mobileNumber: '0400000000',
    emailAddress: 'mock@email.com',
    confirmEmail: 'mock@email.com',
  };

  const personalDetails = {
    title: 'MR',
    firstName: 'Rafael',
    middleName: 'Kingsley Dom',
    lastName: 'Madams',
    dateOfBirth: {
      day: '25',
      month: '12',
      year: '1973',
    },
    hasAuDL: 'yes',
    hasMiddleName: 'yes',
    australianState: 'VIC',
    driverLicenceNumber: '1234567',
    driverLicenceCardNumber: 'AB123456',
    relationshipStatus: 'MARRIED_DEFACTO',
    numberOfDependents: '0',
    crbConsent: true,
    dvsConsent: true,
  };

  const acquisitionComplete = {
    overview: {
      promoCode: '',
      consentGiven: true,
      hasReadImportantDocuments: true,
      applicationReason: '',
      conditionalStop: '',
    },
    contactDetails,
    latitudeId,
    password,
    verification: {
      verificationCode: '',
    },
    emailVerification: {
      verificationCode: '',
    },
    personalDetails,
    otherDetails,
    extraDetails,
    employmentDetails,
    expensesDetails,
    additionalDebtsDetails,
    confirmCreditLimit: confirmCreditLimit.empty,
    preferredCreditLimitDetails,
    acceptance,
    achDetails,
  };

  return {
    acquisitionComplete,
  };
}

function makeNzMockState() {
  const personalDetails = {
    valid: {
      title: 'MR',
      firstName: 'Rafael',
      middleName: '',
      lastName: 'Madams',
      dateOfBirth: {
        day: '25',
        month: '12',
        year: '1973',
      },
    },
  };

  const contactDetails = {
    valid: {
      mobileNumber: '0201234567',
      emailAddress: 'mock@email.com',
      confirmEmail: 'mock@email.com',
    },
  };

  const latitudeId = {
    valid: {
      latitudeId: 'mock@email.com',
      password: '12Abc$^&',
    },
  };

  const password = {
    valid: {
      password: 'Abc123',
    },
  };

  const otherDetails = {
    valid: {
      residentialStatus: 'HOME_OWNER',
      lengthOfCurrentResidency: {
        years: '3',
        months: '0',
      },
      isPostalSameWithResidential: 'yes',
      hasNonDomesticPreviousAddress: false,
      currentAddress: {
        useManualAddress: false,
        address: {
          addressType: 'STREET_ADDRESS',
          poBoxNumber: '',
          propertyName: '',
          unitNumber: '',
          streetNumber: '49',
          streetName: 'Auckland',
          streetType: 'Road',
          suburb: 'Manurewa',
          city: 'Auckland',
          postcode: '1010',
          countryCode: 'NZ',
        },
      },
      previousAddress: {
        useManualAddress: false,
        address: {
          addressType: '',
          poBoxNumber: '',
          propertyName: '',
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          streetType: '',
          suburb: '',
          city: '',
          postcode: '',
          countryCode: 'NZ',
        },
      },
      postalAddress: {
        useManualAddress: false,
        address: {
          addressType: '',
          poBoxNumber: '',
          propertyName: '',
          unitNumber: '',
          streetNumber: '',
          streetName: '',
          streetType: '',
          suburb: '',
          city: '',
          postcode: '',
          countryCode: 'NZ',
        },
      },
    },
  };
  const extraDetails = {
    valid: {
      relationshipStatus: 'MARRIED_DEFACTO',
      numberOfDependents: '0',
      idType: 'NZ_DRIVERS_LICENCE',
      licenceNumber: 'AB123456',
      idNumber: '',
      version: '453',
      expiryDate: {
        day: '01',
        month: '01',
        year: '2030',
      },
      nzResidencyNumber: '',
      issuingCountry: '',
    },
  };

  // TODO update this with correct value
  // https://di.latitudefinancial.com/jira/browse/NZP-518
  const employmentDetails = {
    valid: {
      occupation: 'IT',
      industry: 'COMPUTER_STAFF',
      employerName: 'Latitude',
      lengthOfTimeAtCurrentEmployer: {
        years: '1',
        months: '0',
      },
      lengthOfUnemployment: {
        years: '',
        months: '',
      },
      income: {
        amount: '4000',
        frequency: 'monthly',
      },
      employmentStatus: 'PERMANENT',
      hasOtherIncome: 'yes',
      otherEmploymentStatus: 'RENTAL_INCOME',
      otherIncome: {
        amount: '1000',
        frequency: 'monthly',
      },
      expectedIncomeChange: 'no',
      incomeAdjustmentRequired: 'no',
      conditionalStop: 'no',
    },
  };
  const expensesDetails = {
    valid: {
      isExpensesShared: 'no',
      isRevolvingOrOffsetMortgage: 'no',
      facilityLimit: '0',
      // Your residential expenses
      primaryPropertyMortgageRentBoard: {
        amount: '2000',
        frequency: 'monthly',
      },
      secondaryPropertyMortgageRentBoard: {
        amount: '0',
        frequency: 'monthly',
      },
      propertyRates: {
        amount: '0',
        frequency: 'monthly',
      },
      propertyUtilitiesOther: {
        amount: '0',
        frequency: 'monthly',
      },
      // Your living expenses
      foodGroceriesLifestyleEntertainment: {
        amount: '0',
        frequency: 'monthly',
      },
      healthLifeInsurance: {
        amount: '200',
        frequency: 'monthly',
      },
      generalInsurance: {
        amount: '0',
        frequency: 'monthly',
      },
      transport: {
        amount: '200',
        frequency: 'monthly',
      },
      educationChildcare: {
        amount: '0',
        frequency: 'monthly',
      },
      personalFitnessMedical: {
        amount: '50',
        frequency: 'monthly',
      },
      other: {
        amount: '0',
        frequency: 'monthly',
      },
    },
  };

  const additionalDebtsDetails = {
    valid: {
      hasOtherDebts: 'no',
      homeLoans: '',
      totalCardLimits: '',
      amountOwingOnCreditCard: '',
      loanRepayment: {
        amount: '',
        frequency: 'monthly',
      },
      hasLoanLumpSumPayment: 'no',
      payLumpSumWithExistingSaving: '',
    },
  };

  const creditLimitDetails = {
    valid: {
      creditLimitSource: 'user',
      creditLimit: '5000',
    },
    declined: {
      creditLimit: '',
    },
    referred: {
      creditLimit: '',
    },
    conditionallyApproved: {
      creditLimit: '',
    },
  };

  const preferredCreditLimitDetails = {
    valid: {
      creditLimit: '5000',
    },
    declined: {
      creditLimit: '2000',
    },
    referred: {
      creditLimit: '2000',
    },
    conditionallyApproved: {
      creditLimit: '2000',
    },
  };

  const acceptance = {
    valid: {
      accepted: true,
    },
  };

  const acquisitionComplete = {
    overview: isFeatureOn('promoCode')
      ? {
          promoCode: '',
          applicationReason: 'EXPENSES',
          consentGiven: true,
        }
      : {
          applicationReason: 'EXPENSES',
          consentGiven: true,
        },
    contactDetails: contactDetails.valid,
    latitudeId: latitudeId.valid,
    password: password.valid,
    personalDetails: personalDetails.valid,
    otherDetails: otherDetails.valid,
    extraDetails: extraDetails.valid,
    employmentDetails: employmentDetails.valid,
    expensesDetails: expensesDetails.valid,
    additionalDebtsDetails: additionalDebtsDetails.valid,
    creditLimitDetails: creditLimitDetails.valid,
    preferredCreditLimitDetails: preferredCreditLimitDetails.valid,
    acceptance: acceptance.valid,
  };

  return {
    acquisitionComplete,
  };
}
