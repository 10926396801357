import { isFeatureOn } from 'featureToggles';
import { APPLE_MERCHANT_ID, CHANNEL_DIRECT } from '_config';
import { SAVED, CREATED } from 'store';
import { isAU, isGemAU, isGO } from './byConfig';
import { formatNumber } from './formatNumber';

export * from './convertRateToMonthly';
export * from './mobileNumber';
export { formatNumber } from './formatNumber';
export { getAuthHeader, getLatitudeId } from './getAuth';
export { getDataLayerElements } from './getDataLayerElements';
export { getGender, getAge, convertToYears, getSelectorLabel } from './getDataLayerElementsHelper';
export { getDaysInMonth } from './getDaysInMonth';
export { toSaveApplicationInput } from '../hooks/useSaveApplication/toSaveApplicationInput';
export { getSubFromToken } from './getSubFromToken';
export { initFormConfig } from './initFormConfig';
export { initMessagesObject } from './initMessagesObject';
export { replaceStringVars } from './replaceStringVars';
export { getSiteSubSection, getPageTitle } from './routePageInfo';
export { RouteRedirectHandler } from './routeRedirectHandler';
export { RouteScrollHandler } from './routeScrollHandler';
export { getSessionStorage, setSessionStorage } from './sessionStorage';
export { submitHandler } from './submitHandler';
export { getUploadUrl } from './getUploadUrl';
export { getLaunchDarklyUserKey } from './getLaunchDarklyUserKey';
export { loadTealium } from './loadTealium';
export { getNegativeCapacity } from './negativeCapacityHelpers';
export * from './loadDataDogRum';
export * from './byConfig';
export * from './migrateSavedApplication';

export const displayCreditLimit = storeState => {
  const creditLimit = storeState?.creditLimitAccepted || storeState?.creditLimitApproved;
  return formatNumber(creditLimit);
};

export const canGoBackToMerchant = storeState => {
  return storeState?.applyAndBuy?.upstreamUrl && storeState?.applyAndBuy?.merchantGroupName;
};

export function isGemAUWithMerchantApple(storeState) {
  return isGemAU() && storeState?.applyAndBuy?.merchantId === APPLE_MERCHANT_ID;
}

export const shouldShowAch = storeState => {
  const notGoOrGem = !isGO() && !isGemAU();
  const goGemDirect = (isGO() || isGemAU()) && storeState?.applyAndBuy?.channel === CHANNEL_DIRECT;
  return isAU() && isFeatureOn('ach') && (notGoOrGem || goGemDirect);
};

export const isPreSubmission = applicationStatus => {
  return !applicationStatus || [CREATED, SAVED].includes(applicationStatus);
};
