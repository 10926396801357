import React from 'react';
import { Flex, Icon, Text as ChText } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const Badge = styled(Flex)`
  padding: 4px 12px;
  border-radius: 16px;
  align-items: center;
  height: 24px;
`;

export const BadgeWithIcon = ({ badge, badgeColor, icon }) => (
  <Badge flexShrink={0} backgroundColor={badgeColor} marginBottom={{ base: '8px', md: '4px' }}>
    <Icon as={icon} marginRight="8px" />
    <ChText fontSize="14px" fontWeight="500" whiteSpace="nowrap">
      {badge}
    </ChText>
  </Badge>
);
