import React, { useRef, useState } from 'react';
import { Text, Container, Stack, useBreakpoint } from 'basis';
import { useStoreValue } from 'store';
import { byCard } from 'utils';
import { PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import styled from '@emotion/styled';
import { Icon } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { isFeatureOn } from 'featureToggles';
import { isGemAU } from '../../utils/byConfig';
import { LandingContentItem, OverviewBox } from './LandingPageAu.styles';
import ProfileIcon from '../../images/icons/profile-single.svg';
import HomeIcon from '../../images/icons/home.svg';
import WalletIcon from '../../images/icons/finance-wallet.svg';
import MoneyIcon from '../../images/icons/finance-pay-manual.svg';
import IdentityIcon from '../../images/icons/identity.svg';
import StopwatchIcon from '../../images/icons/stopwatch.svg';

const AccordionWrapper = styled.div`
  position: relative;
  margin: ${props =>
    (props.pinkStyling && isFeatureOn('progressBarNudge')) ||
    !isFeatureOn('progressBarNudge') ||
    !props.isValidStoreOffer
      ? '-32px -20px -20px -20px'
      : '0px -20px -20px -20px'};
  transition: height 0.3s ease;
  height: ${props => (props.isOpen ? 'auto' : '60px')}; // Adjust 48px to match your header height
  overflow: hidden;
  background-color: ${props => (props.pinkStyling ? props.theme.pinkBackgroundColor : 'transparent')};
  margin-bottom: ${isFeatureOn('gemLandingAgreement') ? '16px' : '0px'};
`;
const AccordionHeader = styled.div`
  background-color: ${props => (props.pinkStyling ? props.theme.pinkBackgroundColor : '#f5f5f5')};
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #414141;
`;
const AccordionContent = styled.div`
  padding: 18px;
  background-color: ${props => (props.pinkStyling ? props.theme.lightPinkBackgroundColor : 'white')};
  border-bottom: 1px solid ${props => (props.pinkStyling ? props.theme.darkPinkColor : '#e0e0e0')};
  margin: 0 0 4 0;
`;
const AccordionHeaderContent = styled.div`
  color: ${props => props?.theme?.darkPinkColor ?? '#f5f5f5'};
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0%;
  padding-left: 6px;
`;
export const isValidStoreOffer = storeState => storeState?.offer?.status === 'VALID';
export const AccordionWhenXS = ({ children, pinkStyling }) => {
  const breakpoint = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [storeState] = useStoreValue();
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  // default: 0-374px, xs: 375px-575px,
  if (breakpoint !== 'default' && breakpoint !== 'xs' && !isFeatureOn('gemLandingAgreement')) {
    return children;
  }
  return (
    <AccordionWrapper isOpen={isOpen} pinkStyling={pinkStyling} isValidStoreOffer={isValidStoreOffer(storeState)}>
      <AccordionHeader onClick={toggleAccordion} pinkStyling={pinkStyling}>
        <Text>
          <strong>
            {isGemAU() && pinkStyling && isValidStoreOffer(storeState) ? (
              <AccordionHeaderContent>{storeState.offer.title}</AccordionHeaderContent>
            ) : (
              'Things to know before you start'
            )}
          </strong>
        </Text>
        <Icon
          as={ChevronDownIcon}
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
          transition="transform 0.3s ease"
          boxSize="24px"
        />
      </AccordionHeader>
      <AccordionContent ref={contentRef} pinkStyling={pinkStyling}>
        {children}
      </AccordionContent>
    </AccordionWrapper>
  );
};
export const ApplicationOverview = ({ formData, pinkStyling }) => {
  const items = [
    {
      title: 'Who can apply?',
      body: (
        <>
          <LandingContentItem>
            <img src={ProfileIcon} alt="profile-icon" />
            <Text as="p">You must be aged 18 or older</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={HomeIcon} alt="house-icon" />
            <Text>
              You must be a resident of Australia or a temporary resident who legally has the right to work in Australia
              (and intends to stay) for at least the next 12 months
            </Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      title: byCard({
        [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: 'Details to have handy',
        default: "What you'll need",
      }),
      body: (
        <>
          <LandingContentItem>
            <img src={MoneyIcon} alt="money-icon" />
            <Text>Income, debts and expenses information</Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={IdentityIcon} alt="id-icon" />
            <Text as="p">
              Proof of ID (Australian Driver Licence, Medicare or passport) if you consent to using your ID documents to
              verify your identity
            </Text>
          </LandingContentItem>
          <LandingContentItem>
            <img src={WalletIcon} alt="wallet-icon" />
            <Text as="p">Proof of income (such as payslips, bank statements, tax statements)</Text>
          </LandingContentItem>
        </>
      ),
    },
    {
      title: 'How long will it take?',
      body: (
        <>
          <LandingContentItem>
            <img src={StopwatchIcon} alt="money-icon" />
            <Text>
              You will need 10-15 minutes to complete the application
              <br />
              (If we need additional information from you, we will contact you within 2 business days.)
            </Text>
          </LandingContentItem>
        </>
      ),
    },
  ];
  return (
    <AccordionWhenXS pinkStyling={pinkStyling}>
      <Stack gap="2">
        <OverviewBox>
          {items.map((item, index) => {
            return (
              <Container margin="0 0 6 0" key={`overview-item-${index}`}>
                <Container margin="0 0 4 0">
                  <Text textStyle="heading5" as="h4">
                    {item.title}
                  </Text>
                </Container>
                {item.body}
              </Container>
            );
          })}
        </OverviewBox>
      </Stack>
    </AccordionWhenXS>
  );
};
