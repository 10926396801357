import React from 'react';
import { LayoutContent } from 'components';
import styled from '@emotion/styled';

export const ProgressBar = ({ percentage = 0 }) => {
  const ProgressBarContainer = styled.progress`
    width: 100%;
    height: 8px;
    appearance: none;
    border: none;
    border-radius: 5px;
    overflow: hidden;

    &::-webkit-progress-value {
      background-color: #007bff;
      border-radius: 5px;
    }
    &::-webkit-progress-bar {
      background-color: #d9d9d9;
      border-radius: 5px;
    }
  `;

  const TopLabelContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 5px;
  `;

  const ProgressBarLabel = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #414141;
    font-family: Roboto;
  `;

  return (
    <LayoutContent rowsGap="1">
      <TopLabelContainer>
        <ProgressBarLabel>Application Progress</ProgressBarLabel>
        <ProgressBarLabel>{percentage}%</ProgressBarLabel>
      </TopLabelContainer>
      <ProgressBarContainer value={percentage} max={100} />
      {percentage === 50 && <ProgressBarLabel>You’re halfway there, great work!</ProgressBarLabel>}
    </LayoutContent>
  );
};
