import React from 'react';
import { Button, Container, Text, useTheme } from 'basis';
import { config, PRODUCT_CODE_DAVID_JONES_PRESTIGE } from '_config';
import {
  OverviewContent,
  headerTextDavidJonesPremiere,
  headerTextDavidJonesPrestige,
} from 'siteContent/overviewContent';
import { isNZ } from 'utils';
import { HeaderBannerTextStyle } from './Overview.styles';

const HeaderBannerTextArea = ({ openFeatureAndBenefitsModal }) => {
  const { headerTextLine2, headerIntroText } = OverviewContent[config.countryCode];
  const headerText = isNZ()
    ? OverviewContent.NZ.headerText
    : config.productCode === PRODUCT_CODE_DAVID_JONES_PRESTIGE
    ? headerTextDavidJonesPrestige
    : headerTextDavidJonesPremiere;

  const theme = useTheme();
  return (
    <HeaderBannerTextStyle theme={theme}>
      <Container
        padding="4"
        padding-xs="6 6 6 5"
        padding-md="4 0 6 5"
        margin="0"
        margin-md="4 0 0 5"
        margin-lg="0 0 0 5"
        margin-xl="4 0 0 5"
        width="100%"
        width-xs="100%"
        width-sm="auto"
      >
        <Text as="h1" textStyle="heading4" textStyle-md="heading3" textStyle-lg="heading4" textStyle-xl="heading2">
          {headerText}
          {headerTextLine2 && (
            <>
              <br />
              {headerTextLine2}
            </>
          )}
        </Text>
        <Text margin="4 0">{headerIntroText}</Text>
        <Button width="100%" width-xs="100%" width-sm="auto" variant="secondary" onClick={openFeatureAndBenefitsModal}>
          View features and benefits
        </Button>
      </Container>
    </HeaderBannerTextStyle>
  );
};

export { HeaderBannerTextArea };
