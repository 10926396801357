import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, Errors } from 'components';
import { useWaitAndRedirect, useEnv, useCountdownTimer } from 'hooks';
import { Container, Text, LoadingIcon, Flex, Stack } from 'basis';
import { useTheme } from '@emotion/react';
import { useStoreValue } from 'store';
import { isFeatureOn } from 'featureToggles';
import { byCountry, isAU } from 'utils';

import { REFERRED, PROCESSING_TIMEOUT } from 'store/constants';

const useClearableCountdown = (updateStore, hasError) => {
  const history = useHistory();
  const getEnv = useEnv();

  const redirectToReferred = () => {
    updateStore({
      activeStep: '/outcome',
      applicationStatus: REFERRED,
      applicationStatusReason: PROCESSING_TIMEOUT,
    });
    history.push('/outcome');
  };

  const countdown = useCountdownTimer(
    getEnv(
      byCountry({
        NZ: 'REACT_APP_APPLICATION_PROCESS_TIME_IN_SEC',
        AU: 'REACT_APP_AU_APPLICATION_PROCESS_TIME_IN_SEC',
      }),
    ),
    redirectToReferred,
  );
  const clearCountdown = countdown[2];

  useEffect(() => {
    hasError && clearCountdown && clearCountdown();
  }, [clearCountdown, hasError]);
};

export const ProcessingAcceptance = () => {
  const theme = useTheme();
  const [storeState, updateStore] = useStoreValue();
  const { applicationId, applicationRef, applicationErrors } = storeState;
  const noProgressStepper = isFeatureOn('progressBarNudge');

  const { retry, loading } = useWaitAndRedirect({
    applicationId,
    testId: 'longPollingWhileProcessingAcceptance',
  });

  useClearableCountdown(updateStore, !!applicationErrors);

  if (applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  const ProcessingAcceptanceNZ = () => (
    <Stack gap="8">
      <Text textStyle="heading4" color={theme.headingColor} align="center">
        Please wait a minute while we process your information.
      </Text>
      <Container textAlign="center">
        <LoadingIcon />
      </Container>
      <Text as="p" textStyle="subtitle2" align="center">
        This typically takes less than 60 seconds.
      </Text>
    </Stack>
  );

  const ProcessingAcceptanceAU = () => (
    <Stack gap="8">
      <Container textAlign="center">
        <LoadingIcon size="large" color={theme.spinnerColor} />
      </Container>
      <Text textStyle="heading4" color="black" align="center">
        We're updating your credit limit
      </Text>
    </Stack>
  );

  return (
    <LayoutPage noProgressStepper={noProgressStepper}>
      <Container testId="processingPcaAcceptance" height="400" padding="6" hasBreakpointWidth>
        <Flex height="100%" placeItems="center">
          {isAU() ? <ProcessingAcceptanceAU /> : <ProcessingAcceptanceNZ />}
        </Flex>
      </Container>
    </LayoutPage>
  );
};
