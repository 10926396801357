import React from 'react';
import { Button, Container, Text, Stack, Grid, Message } from 'basis';
import { Modal } from 'components/modal/Modal';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { isFeatureOn } from 'featureToggles';
import { InitialDisclosureStatement } from './InitialDisclosureStatement';
import { ConditionsOfUse } from './ConditionsOfUse';

const modalStyle = {
  width: 960,
};

const Scrollable = styled.div`
  overflow-y: scroll;
  max-height: calc(100vh - 340px);
`;

export const StatementModal = ({
  isOpen,
  onAccept,
  onReject,
  applicationDate,
  trackEventSender,
  getLinkWithAnalytics,
  creditLimit,
}) => {
  const theme = useTheme();
  const statementDate = new Date(applicationDate).toLocaleDateString();

  return (
    <Modal isOpen={isOpen} onClose={onAccept} style={modalStyle} disableExitShortcuts>
      <Container padding="3" padding-md="6" testId="pcaTermsAndConditionsModal">
        <Text textStyle="heading5" color={theme.headingColor} margin="0 0 6 0" margin-md="0 0 10 0">
          {isFeatureOn('dynamicCreditLimit')
            ? 'Please accept the Conditions of Use and Initial Disclosure Statement'
            : 'Finalise your application'}
        </Text>
        <Scrollable>
          <Stack gap="6" gap-md="10">
            <Text>
              Gem Visa is a general purpose credit card. It gives 6 months interest free on all Visa purchases over
              $250. It may also be used for long term retail finance in around 2,000 participating stores throughout New
              Zealand
            </Text>
            <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
              <strong>Credit Limit: ${creditLimit}</strong> - subject to change once the assessment of your application
              has been completed
            </Message>
            <Text textStyle="heading6" testId="statementDate">
              Statement date: {statementDate}
            </Text>
            <InitialDisclosureStatement trackEventSender={trackEventSender} creditLimit={creditLimit} />
            <ConditionsOfUse trackEventSender={trackEventSender} getLinkWithAnalytics={getLinkWithAnalytics} />
          </Stack>
        </Scrollable>
        <Container margin="4 0 0 0">
          <Grid>
            <Grid.Item colSpan="all" colSpan-md="2-3">
              <Container padding="2 0" padding-md="2">
                <Button type="button" width="100%" variant="secondary" testId="reject" onClick={onReject}>
                  Close
                </Button>
              </Container>
            </Grid.Item>
            <Grid.Item colSpan="all" colSpan-md="4-5">
              <Container padding="2 0" padding-md="2">
                <Button type="button" width="100%" testId="accept" onClick={onAccept}>
                  I accept
                </Button>
              </Container>
            </Grid.Item>
            <Grid.Item colSpan-md="6-7">
              <span />
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </Modal>
  );
};
