import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useOktaClient, useCountdownTimer, useEnv } from 'hooks';
import { BasisOneTimeCode } from './BasisOneTimeCode';

function getMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const minutesToDisplay = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return minutesToDisplay;
}
function getRemainingSeconds(seconds) {
  const remainingSeconds = seconds % 60;
  const secondsToDisplay = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return secondsToDisplay;
}

export function OneTimeCode({ resendMsecs, onResendCodeClick, onResendCodeError, resend, formData, theme, ...rest }) {
  const [state, setState] = useState('locked');
  const [showRedMsg, setShowRedMsg] = useState(false);
  const [doesCountDown, setDoesCountDown] = useState(true);
  const requestAfterLock = useRef(false);
  const timeout = useRef(null);
  const { factor } = rest;
  const getEnv = useEnv();
  const defaultTimer = getEnv('REACT_APP_AU_VERIFY_COUNTDOWNTIMER_EXPIRATION_IN_SEC');

  const onTimedOut = () => {
    setShowRedMsg(true);
  };

  const handleResendCodeSuccess = () => {
    setState('success');
    resetTimer();
    setDoesCountDown(true);
  };
  const handleResendCodeError = error => {
    onResendCodeError && onResendCodeError(error);
    setState('error');
  };
  const { loading, resendOTP: resendOTPViaOkta } = useOktaClient({
    onSuccess: handleResendCodeSuccess,
    onError: handleResendCodeError,
  });

  const resendOTP = useCallback(async () => {
    if (!resend) {
      setDoesCountDown(false);
      return resendOTPViaOkta(factor);
    }

    const result = await resend();
    if (result.success) {
      setState('success');
    } else {
      onResendCodeError && onResendCodeError(result.error);
      setState('error');
    }

    return result;
  }, [factor, onResendCodeError, resend, resendOTPViaOkta]);

  const [seconds, resetTimer] = useCountdownTimer(defaultTimer, onTimedOut, doesCountDown);
  const countDownText = `${getMinutes(seconds)}:${getRemainingSeconds(seconds)}`;

  const handleResendCodeClick = evt => {
    evt.preventDefault();
    onResendCodeClick && onResendCodeClick();
    setShowRedMsg(false);
    formData.resetForm({
      values: {
        verificationCode: '',
      },
      errors: {},
    });
    if (state === 'locked') {
      requestAfterLock.current = true;
      setState('pending');
    } else {
      resendOTP();
    }
  };

  const clearTimer = useCallback(() => {
    clearTimeout(timeout.current);
    timeout.current = null;
  }, [timeout]);

  useEffect(() => {
    if (!timeout.current && (state === 'locked' || state === 'success' || state === 'error')) {
      timeout.current = setTimeout(() => {
        setState('ready');
        clearTimer();
        if (requestAfterLock.current) {
          requestAfterLock.current = false;
          resendOTP();
        }
      }, resendMsecs);
    }
  }, [state, resendMsecs, requestAfterLock, resendOTP, clearTimer, factor]);

  useEffect(() => {
    return () => clearTimer();
  }, [clearTimer]);

  return (
    <BasisOneTimeCode
      state={state}
      loading={loading}
      onResendCodeClick={handleResendCodeClick}
      theme={theme}
      countDownText={countDownText}
      showRedMsg={showRedMsg}
      {...rest}
    />
  );
}
