import React from 'react';
import { Container, Text, List, Button, Flex, Stack } from 'basis';
import { Modal } from 'components/modal/Modal';

import styled from '@emotion/styled';

const IconWrapper = styled.div`
  width: 60px;
`;

const ContentWrapper = styled.div`
  margin-left: 10px;
  ul {
    margin: 2px 0 2px 0;
    li::before {
      height: 6px;
      width: 6px;
    }
  }
`;

const ModalContent = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

const ItemTitle = styled.div`
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`;

/**
 * @typedef {{
 *    icon: JSX.Element
 *    title: string,
 *    list: Array.<string>
 *  }} ContentList
 *
 * @typedef {
 *  title: string
 *  content: string | JSX.Element
 *   items: Array.<ContentList>
 * } ModalConfig
 *
 * @param {boolean} isOpen flag indicates the modal is open.
 * @param onClose modal close callback function.
 * @param {ModalConfig} modal the modal content configuration.
 */
export function ExpenseModal({ isOpen, onClose, modal }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="3" padding-md="8">
        <Container margin="0 0 4 0">
          <Text textStyle="heading5">{modal.title}</Text>
        </Container>
        <ModalContent>
          {modal.content && (
            <Container padding="0 0 1 0" margin="2 0 3 0">
              {modal.content}
            </Container>
          )}
          <Container padding="2 0 1 0">
            <Stack gap="4">
              {modal.items.map((item, index) => (
                <Flex placeItems="top left" key={`modal-items-${index}`}>
                  <IconWrapper>{item.icon}</IconWrapper>
                  <ContentWrapper>
                    <Container margin="0 0 3 0">
                      <ItemTitle>{item.title}</ItemTitle>
                    </Container>
                    <List textStyle="body1" margin="3 1 3 12">
                      {item.list.map((listItem, i) => (
                        <List.Item key={`modal-list-${i}`}>
                          <Text>{listItem}</Text>
                        </List.Item>
                      ))}
                    </List>
                  </ContentWrapper>
                </Flex>
              ))}
            </Stack>
          </Container>
          <Flex placeItems="center center">
            <Container padding="2" margin="8 0 0 0">
              <Button width="160px" onClick={onClose}>
                Close
              </Button>
            </Container>
          </Flex>
        </ModalContent>
      </Container>
    </Modal>
  );
}
