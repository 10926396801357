import React from 'react';
import { Text, useBreakpoint, Button, Container } from 'basis';
import { useStoreValue } from 'store';
import { ListItem } from '@chakra-ui/react';
import { byCard } from 'utils/byConfig';
import { PRODUCT_CODE_AFS } from '_config';
import styled from '@emotion/styled';
import { OfferCondition } from 'components/offer-tile/OfferTile';
import { isFeatureOn } from 'featureToggles';
import { UnorderedList } from './Explain';
import { AccordionWhenXS, isValidStoreOffer } from './ApplicationOverview';
import { OverviewBox } from './LandingPageAu.styles';

const AccordionTextContent = styled.div`
  background-color: ${props => props.theme.lightPinkBackgroundColor};
  padding-left: 6px;
`;
export const ApplicationOverviewGemVisaAu = ({ openFeatureAndBenefitsModal, theme, pinkStyling = true }) => {
  const featureBenefits = [
    'Instant 0% interest on $250+ purchases for 6 months',
    '0% Interest Free plans from 6 – 60 months at 100s of retailers online and in-store',
    'Enjoy 6% off with Booking.com and 10% off with Expedia and Wotif',
  ];
  const buttonLabel = byCard({
    [PRODUCT_CODE_AFS]: 'View all features and rates',
    default: 'View all features and rates',
  });
  const breakpoint = useBreakpoint();
  const [storeState] = useStoreValue();
  return (
    <>
      {!['md', 'lg', 'xl'].includes(breakpoint) && isValidStoreOffer(storeState) && (
        <AccordionWhenXS pinkStyling>
          <AccordionTextContent>
            <Text>{storeState.offer.description}</Text>
            {storeState.offer.promoEnd && (
              <OfferCondition>
                New customers only, must be approved by {storeState.offer.promoEnd}. T&Cs apply.
              </OfferCondition>
            )}
          </AccordionTextContent>
        </AccordionWhenXS>
      )}
      {isFeatureOn('gemLandingAgreement') && (
        <OverviewBox theme={theme}>
          <Text textStyle="heading5" as="h4" margin="0 0 0 1">
            Featured benefits
          </Text>
          <UnorderedList>
            {featureBenefits.map((benefit, index) => (
              <ListItem key={`featured-benefit-item-${index}`}>
                <Text>{benefit}</Text>
              </ListItem>
            ))}
          </UnorderedList>
          <Container>
            <Button
              variant="secondary"
              data-testid="application-overview-btn"
              type="button"
              onClick={openFeatureAndBenefitsModal}
            >
              {buttonLabel}
            </Button>
          </Container>
        </OverviewBox>
      )}
    </>
  );
};
