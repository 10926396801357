import React from 'react';
import { Text, Container } from 'basis';
import { isFeatureOn } from 'featureToggles';
import { CrbConsent } from './CrbConsent';
import { DvsConsent } from './DvsConsent';

export const IdvConsents = () => {
  return (
    <div>
      {isFeatureOn('progressBarNudge') ? (
        <Text textStyle="heading5">Save time with fast and secure online identity verification</Text>
      ) : (
        <Text textStyle="heading5">Verify your identity</Text>
      )}
      <Container padding="3 0">
        {isFeatureOn('progressBarNudge') ? (
          <Text>
            We use safe and reliable checks to verify your identity quickly, so you can proceed without delays.
          </Text>
        ) : (
          <Text>These checks are safe and secure.</Text>
        )}
      </Container>
      <CrbConsent />
      <DvsConsent />
    </div>
  );
};
