import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { LayoutPage, Form } from 'components';
import { useStoreValue } from 'store';
import { getDataLayerElements } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import { APPLY_BTN_LABEL, STEP_AGREE_AND_CONTINUE } from '_config';
import GemBannerMobile from 'images/banner/gem-banner-new.png';
import {
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
  useDataLayerAndAnalyticsLinks,
  useValidateOffers,
} from 'hooks';
import { LandingSubHeader } from 'components/merchant/LandingSubHeader';
import { isFeatureOn } from 'featureToggles';
import { OfferTile } from 'components/offer-tile/OfferTile';
import { useAdblockDetector } from '../../hooks/useAdblockDetector';
import { Banner, BannerImage, ContentWrapper } from './LandingPageAu.styles';
import { FeaturesAndBenefitsModal } from './FeaturesAndBenefitsModal';
import { HeaderBannerTextArea } from './HeaderBannerTextArea';
import { ApplicationOverviewGemVisaAu } from './ApplicationOverviewGemVisaAu';
import { StartApplication } from './StartApplication';

const FORM_ID = 'overview';
const formTealiumLocation = 'landing';
const pageTealiumLocation = 'what-to-expect';

export function LandingPageGemVisaAu() {
  const theme = emotionUseTheme();
  const history = useHistory();
  const [featureAndBenefitsModalOpen, setFeatureAndBenefitsModalOpen] = useState(false);
  const { trackEvent, getQueryString } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const [storeState, updateStore] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const initialValues = useFormInitialValues(FORM_ID);
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector(
    `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.sync.js`,
  );

  useEffect(() => {
    if (isFeatureOn('balanceTransfer') && storeState?.applyAndBuy?.promoCode) {
      validateOffer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStartApplication = () => {
    updateStore({
      activeStep: STEP_AGREE_AND_CONTINUE,
    });

    trackEventSender({
      category: 'application',
      action: 'application-started',
      location: formTealiumLocation,
      label: APPLY_BTN_LABEL,
    });

    history.push(STEP_AGREE_AND_CONTINUE);
  };

  const { validateOffer } = useValidateOffers({
    onError: handleErrorCallback,
  });

  const openFeatureAndBenefitsModal = () => {
    setFeatureAndBenefitsModalOpen(true);
    trackEvent({
      event: {
        category: 'text-link',
        action: 'interaction',
        location: pageTealiumLocation,
        label: 'View features and benefits',
      },
      ...getDataLayerElements(storeState),
    });
  };

  const closeFeatureAndBenefitsModal = () => {
    setFeatureAndBenefitsModalOpen(false);
  };

  const getLinkWithAnalytics = ({ label, url }) => {
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'what-to-expect',
        label,
      },
    });

    const link = new URL(url);
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  return (
    <LayoutPage noSessionTimeout={!storeState.acquisition.overview.consentGiven} hideBackButton noChildrenPadding>
      <FeaturesAndBenefitsModal
        trackEventSender={trackEventSender}
        getLinkWithAnalytics={getLinkWithAnalytics}
        isOpen={featureAndBenefitsModalOpen}
        onClose={closeFeatureAndBenefitsModal}
      />

      <LandingSubHeader {...storeState.applyAndBuy} />
      <Banner>
        <Container width="100%" padding="0" padding-lg="0">
          <HeaderBannerTextArea openFeatureAndBenefitsModal={openFeatureAndBenefitsModal} />
          <BannerImage theme={theme}>
            <img src={GemBannerMobile} alt="LFrame card" />
          </BannerImage>
        </Container>
        <div className="banner-right" />
      </Banner>
      <Container padding="0 5 5 5" paddingMd="0">
        <Form
          id={FORM_ID}
          initialValues={initialValues}
          onSubmit={handleStartApplication}
          hideFormButtons
          disableFormCache
        >
          <ContentWrapper theme={theme}>
            <Container bg="white" margin="auto">
              <Grid cols="10" colsGap="8">
                <Grid.Item colSpan="all" colSpan-md="0-3">
                  <OfferTile />
                  <ApplicationOverviewGemVisaAu
                    theme={theme}
                    openFeatureAndBenefitsModal={openFeatureAndBenefitsModal}
                  />
                </Grid.Item>
                <Grid.Item colSpan="all" colSpan-md="4-10">
                  <StartApplication
                    trackEventSender={trackEventSender}
                    formTealiumLocation={formTealiumLocation}
                    theme={theme}
                    isAdblockCheckComplete={isAdblockCheckComplete}
                    isAdblockerDetected={isAdblockerDetected}
                  />
                </Grid.Item>
              </Grid>
            </Container>
          </ContentWrapper>
        </Form>
      </Container>
    </LayoutPage>
  );
}
