import React from 'react';
import { displayCreditLimit } from 'utils';
import { useTheme } from '@emotion/react';
import { config } from '_config';
import { PhoneNumberLink, ReturnButton } from 'components';
import {
  UPLOAD_PAYSLIPS,
  PROOF_OF_INCOME_LIST,
  PROOF_OF_ADDRESS_LIST,
  UPLOAD_BTN_LABEL,
} from '_config/_constants/documentUpload';
import phone from 'images/icon-phone.svg';
import { Accordion, Container, Grid, Text, useTheme as useBasisTheme } from 'basis';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { PhoneIcon, List, CustomWrapper } from '../Outcome.styles';
import { UploadDocumentButton } from '../components/UploadDocumentButton';

export const PayslipsUpload = ({ storeState, POIAML, POIPOA, POI }) => {
  const basisTheme = useBasisTheme();
  const theme = useTheme();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { applicationRef } = storeState;
  const creditLimit = displayCreditLimit(storeState);
  return (
    <Grid preset="page" colsGap={12}>
      <Grid.Item colSpan="all" colSpan-sm="1-6" colSpan-md="1-6" colSpan-lg="2-9" colSpan-xl="2-9">
        <Grid rowsGap={8}>
          <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme.headingColor}>
            Based on the information you’ve given us we can conditionally approve you for ${creditLimit}.
          </Text>

          <Text>
            Your application number is <b>{applicationRef}</b>
          </Text>

          <Text>
            {POIPOA && (
              <b>All you need to do to complete your application is upload your payslips and prove your address.</b>
            )}
            {POI && <b>All you need to do to complete your application is upload your payslips.</b>}
            {POIAML && <b>All you need to do to complete your application is prove your income and confirm your ID.</b>}
            <br />
            Once you’ve uploaded your documents, your application is complete and our team will review and be in touch
            within 2 days.
          </Text>

          {POIAML && (
            <>
              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                Step 1: Confirm your ID
              </Text>
              <Grid>
                <Grid.Item rowSpan="1">
                  <PhoneIcon src={phone} theme={basisTheme} />
                </Grid.Item>
                <Grid.Item rowSpan="1">
                  <Text margin="0">
                    If you have a smart phone and current NZ Passport or drivers licence, you can call us on{' '}
                    <PhoneNumberLink
                      id="originationsTeam"
                      onClick={() =>
                        trackEventSender({
                          category: 'contact-us',
                          action: 'phone-link',
                          location: 'outcome',
                          label: config.phoneNumbers.originationsTeam,
                        })
                      }
                    />{' '}
                    to complete your ID verification digitally and talk further about your income.
                  </Text>
                  <Text margin="8 0">
                    <b>
                      If we’ve not heard from you within the next 2 days we’ll be in touch to help complete your
                      application.
                    </b>
                  </Text>
                </Grid.Item>
              </Grid>
            </>
          )}

          <Text as="h1" textStyle="heading5" color={theme.headingColor}>
            {POI ? '' : POIPOA ? 'Step 1: ' : 'Step 2: '}Upload payslips
          </Text>

          {UPLOAD_PAYSLIPS.map((data, key) => {
            return (
              <div key={key}>
                <Text>
                  {POI ? 'We’ll need you to upload 2 consecutive payslips that clearly show:' : data.listTitle}
                </Text>
                {data.listItem && (
                  <List marginTop="10" noPadding>
                    {data.listItem.map((item, itemKey) => (
                      <li key={itemKey}>{item}</li>
                    ))}
                  </List>
                )}
                {key !== PROOF_OF_INCOME_LIST.length - 1 && <br />}
              </div>
            );
          })}

          {POIPOA && (
            <>
              <Text as="h1" textStyle="heading5" color={theme.headingColor}>
                Step 2: Upload proof of address
              </Text>

              <Text>
                If the document you choose to prove your income doesn’t clearly show your current address, we’ll also
                need you to upload an additional document that does. See the full list of documents we accept below.
              </Text>

              <Accordion>
                <Accordion.Item initiallyOpen>
                  <CustomWrapper>
                    <Accordion.Item.Header>What documents do we accept?</Accordion.Item.Header>
                  </CustomWrapper>
                  <Accordion.Item.Content padding="6">
                    {PROOF_OF_ADDRESS_LIST.map((data, key) => {
                      return (
                        <div key={key}>
                          <Text>
                            <b>{data.listTitle}</b>
                          </Text>
                          {data.listItem && (
                            <List marginTop="10" noPadding>
                              {data.listItem.map((item, itemKey) => (
                                <li key={itemKey}>{item}</li>
                              ))}
                            </List>
                          )}
                          {key !== PROOF_OF_ADDRESS_LIST.length - 1 && <br />}
                        </div>
                      );
                    })}
                  </Accordion.Item.Content>
                </Accordion.Item>
              </Accordion>
            </>
          )}
          {POIPOA && (
            <Text>
              <b>You can upload all your documents through the same portal. </b>
            </Text>
          )}

          <UploadDocumentButton label={UPLOAD_BTN_LABEL} storeState={storeState} />
        </Grid>

        <Text margin="2 0" textStyle="legal">
          Files can be PDFs, photographs or scanned formats.{' '}
        </Text>

        <Container padding-md="2 0 10">
          <ReturnButton variant="text" />
        </Container>
      </Grid.Item>
    </Grid>
  );
};
