const { default: styled } = require('@emotion/styled');

export const DividerWithText = styled.hr`
  position: relative;
  border: 0;
  margin: 20px 0 4px;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;

  &:before {
    content: '';
    background: #7f7f7f;
    position: absolute;
    top: calc(50% - 1px);
    width: calc(100% - 32px);
    height: 1px;
    margin: 0 16px;
  }
  &:after {
    display: flex;
    justify-content: center;
    align-items: center;
    content: attr(data-content);
    position: relative;
    color: #ffffff;
    width: 35px;
    height: 35px;
    background-color: #7f7f7f;
    line-height: 1;
    border-radius: 500px;
  }
`;
