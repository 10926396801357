import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreValue } from 'store';
import { LayoutPage, LayoutContent, FormRenderer, Form } from 'components';
import { initFormConfig, submitHandler, isAU, byCountry } from 'utils';
import { useSetUserCredentials, useSteps, useDataLayerAndAnalyticsLinks } from 'hooks';
import { DEV_FEATURES } from '__dev/devFeatures';
import { STEP_PROCESSING } from '_config';
import styled from '@emotion/styled';
import { isFeatureOn } from 'featureToggles';
import {
  validPasswordSymbolsPattern,
  validPasswordLowerLettersPattern,
  validPasswordUpperLettersPattern,
  validPasswordNumbersPattern,
  validPasswordMinLength,
} from './validations';
import { Password } from './Password';

const LatitudeIDWrapper = styled.div`
  & input[name='latitudeId'] {
    padding-left: ${isAU() ? 0 : 'auto'};
  }
`;

export function LatitudeId() {
  const [storeState, updateStore] = useStoreValue();
  const { acquisition } = storeState;
  const { nextStep } = useSteps();
  const history = useHistory();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const setUserCredentials = useSetUserCredentials();
  const noProgressStepper = isAU() && (!isFeatureOn('emailVerification') || isFeatureOn('progressBarNudge'));

  const next = useCallback(() => {
    const step = byCountry({
      NZ: nextStep,
      AU: isFeatureOn('emailVerification') ? STEP_PROCESSING : nextStep,
    });
    updateStore({
      applicationErrors: null,
      isLatitudeIdPasswordSet: true,
      activeStep: step,
    });

    history.push(step);
  }, [history, nextStep, updateStore]);

  const onSubmit = ({ values, errors, setErrors }) => {
    async function setAndGoNext() {
      trackEventSender({
        category: 'application',
        action: 'application-navigation',
        location: 'latitude-id',
        label: byCountry({
          AU: 'Set up now',
          NZ: 'Next',
        }),
      });

      if (DEV_FEATURES.OTP_SKIP) {
        next();
      }
      const { error } = await setUserCredentials(values.password);
      const validationErrorMsg = error?.response?.data?.message?.split('password:')[1];
      if (error) {
        setErrors({ password: validationErrorMsg || error.message });
      } else {
        next();
      }
    }

    submitHandler({ submit: setAndGoNext, errors });
  };

  const initialValues = useMemo(
    () => ({
      latitudeId: acquisition.contactDetails.emailAddress,
      password: '',
    }),
    [acquisition],
  );

  const validationMessages = useMemo(
    () => ({
      required: 'Please enter a password that matches the criteria below.',
      minLength: 'Please ensure your password matches the criteria below.',
      maxLength: 'Please ensure your password matches the criteria below.',
      invalid: 'Please ensure your password matches the criteria below.',
    }),
    [],
  );

  const formConfig = useMemo(
    () =>
      initFormConfig([
        {
          component: 'InfoBlock',
          name: 'info',
          text: byCountry({
            NZ: 'A Latitude ID gives you access to both our Mobile App and Online Latitude Service Centre.',
            AU: isFeatureOn('emailVerification')
              ? `Create the password you'd like to use for the Latitude App and Latitude Service Centre.`
              : 'A Latitude ID gives you access to both our Mobile App and Online Latitude Service Centre.',
          }),
        },
        {
          component: 'Input',
          name: 'latitudeId',
          label: byCountry({
            AU: isFeatureOn('emailVerification') ? 'Your email address' : 'Latitude ID username',
            NZ: 'Latitude ID',
          }),
          testId: 'latitudeIdField',
          color: isAU() ? 'white' : null,
          disabled: true,
        },
        {
          component: 'CustomComponent',
          children: Password,
          name: 'password',
          label: 'Create a password',
          testId: 'password',
          validate: {
            props: {
              minLength: validPasswordMinLength,
              validPattern: [
                validPasswordSymbolsPattern,
                validPasswordLowerLettersPattern,
                validPasswordUpperLettersPattern,
                validPasswordNumbersPattern,
              ],
            },
            messages: validationMessages,
          },
        },
      ]),
    [validationMessages],
  );

  return (
    <LayoutPage noProgressStepper={noProgressStepper}>
      <Form id="latitudeId" initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
        {formData => {
          return (
            <LatitudeIDWrapper>
              <LayoutContent>
                <FormRenderer config={formConfig} formData={formData} />
              </LayoutContent>
            </LatitudeIDWrapper>
          );
        }}
      </Form>
    </LayoutPage>
  );
}
