import styled from '@emotion/styled';

export const StyledUL = styled.ul`
  margin-top: 0;
  padding-left: 16px;
  line-height: 24px;
`;

export const StyledULWideLeftPadding = styled.ul`
  margin-top: 8px;
  padding-left: 14px;
`;

export const StyledDivSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLeftDivWidth75 = styled.div`
  width: 75%;
`;

export const StyledDivAlignRight = styled.div`
  width: 23%;
  text-align: right;
`;

export const LightHr = styled.hr`
  border: 0.5px solid #e0e0e0;
`;

export const StyledTable = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const StyledTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

export const StyledTableCell = styled.div`
  flex: ${props => props.flex || 1};
  padding: 0 8px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;

export const StyledTableHeader = styled(StyledTableRow)`
  background-color: #f5f5f5;
  font-weight: 500;
`;

export const ballotBoxWithX = '☒';

export const ballotBoxWithTick = '☑';
