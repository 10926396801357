import { numbersOnlyPattern } from '../_constants';
import { minCreditLimit, creditLimitIncrement } from './constants';

export const confirmCreditLimit = creditLimitApproved => {
  const maxCreditLimit = creditLimitApproved || 40000;
  return [
    {
      label: 'To meet my needs and objectives I would like a credit limit of',
      component: 'Input',
      variant: 'numeric',
      name: 'newCreditLimit',
      testId: 'newCreditLimit',
      prefix: '$',
      validate: {
        props: {
          minValue: minCreditLimit,
          maxValue: maxCreditLimit,
          minLength: 3,
          maxLength: 6,
          incrementValue: creditLimitIncrement,
          validPattern: numbersOnlyPattern,
        },
        messages: {
          required: 'Please tell us your preferred credit limit in dollars.',
          minValue: `Please enter a credit limit amount of \${{minValue}} or more in \${{incrementValue}} increments.`,
          maxValue: `The maximum amount you can enter is $ ${maxCreditLimit}.`,
          minLength: 'Must have at least {{minLength}} digits.',
          maxLength: 'Must have at most {{maxLength}} digits.',
          incrementValue: `Please enter an amount in \${{incrementValue}} increments.`,
          invalid: 'Please enter a whole dollar amount, without cents.',
        },
      },
      onChange: formData => {},
    },
  ];
};
