import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreValue } from 'store';
import { Text } from 'basis';
import { LayoutPage, LayoutContent, Form, FormRenderer } from 'components';
import messageLabels from 'components/message/messageLabels.json';
import { ColoredMessage } from 'components/message/ColoredMessage';
import { isFeatureOn } from 'featureToggles';
import { config, STEP_ABOUT_YOU } from '_config';
import { byCountry, initFormConfig, isAU, submitHandler } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import { useFormInitialValues, useQueryParam } from 'hooks';
import { ConsentMessage } from './PersonalDetails.style';

const FORM_ID = 'personalDetails';
function PersonalDetails() {
  const [storeState, updateStore] = useStoreValue();
  const { acquisition, ...states } = storeState;
  const formConfig = useMemo(() => initFormConfig(config.formConfigs[FORM_ID], storeState), [storeState]);

  const history = useHistory();
  const { trackEvent } = useEventTracking();
  const initialValues = useFormInitialValues(FORM_ID);
  const isBiometricsCancelled = useQueryParam('cancel');

  useEffect(() => {
    if (isBiometricsCancelled) {
      // biometrics cancellation will redirect to this page
      updateStore({
        biometricsVerification: 'CANCELLED',
        activeStep: STEP_ABOUT_YOU,
        biometricsResult: null,
      });
    }
  }, [isBiometricsCancelled, updateStore]);

  const onSubmit = ({ values, errors }) => {
    function updateStoreAndRedirect() {
      const newAcquisition = {
        ...acquisition,
        personalDetails: { ...values },
      };

      if (isAU()) {
        newAcquisition.extraDetails.idType = 'AU_DRIVERS_LICENCE';
      }

      updateStore({
        activeStep: '/address-details',
        acquisition: newAcquisition,
      });

      history.push('/address-details');

      trackEvent({
        event: {
          category: 'application',
          action: 'application-navigation',
          location: 'about-you',
          label: byCountry({
            AU: 'Next - Address details',
            NZ: 'Next',
          }),
        },
        ...getDataLayerElements({ acquisition: newAcquisition, ...states }),
      });
    }

    submitHandler({ submit: updateStoreAndRedirect, errors });
  };

  return (
    <LayoutPage noSessionTimeout>
      <ConsentMessage>
        <Form
          id={FORM_ID}
          initialValues={initialValues}
          onSubmit={onSubmit}
          showSaveDraftButton
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
          showBackButton={false}
        >
          {formData => {
            return (
              <LayoutContent>
                {isFeatureOn('progressBarNudge') && (
                  <ColoredMessage color="purple" borderColor="#5e47b9">
                    <Text textStyle="heading6" as="h4">
                      {messageLabels.personalDetails.purpleMessage.title}
                    </Text>
                    <Text textStyle="heading6.bold" margin="1 0 0 0">
                      {messageLabels.personalDetails.purpleMessage.body}
                    </Text>
                  </ColoredMessage>
                )}
                <FormRenderer config={formConfig} formData={formData} />
              </LayoutContent>
            );
          }}
        </Form>
      </ConsentMessage>
    </LayoutPage>
  );
}

export { PersonalDetails };
