import { Message } from 'basis';
import React from 'react';
import { config } from '_config';

/**
 * @param {"email" | "sms"} contactType - The type of OTP is sent to.
 * @returns {React.ReactNode}
 */
export const GuideCodeNotReceived = ({ contactType }) => {
  return contactType === 'sms' ? <SmsCodeNotReceived /> : <EmailCodeNotReceived />;
};

function SmsCodeNotReceived() {
  const supportNumber = config.phoneNumbers.originationsTeam;
  return (
    <Message severity="info-or-minor" bg="secondary.lightBlue.t25" title="Have a different contact number?">
      If you are not receiving the SMS verification code please call our Contact Centre for assistance on{' '}
      <a href={`tel:${supportNumber}`}>{supportNumber}</a> and quote <strong>`Mobile number update`</strong> over the
      phone.
    </Message>
  );
}

function EmailCodeNotReceived() {
  return (
    <Message severity="info-or-minor" bg="secondary.lightBlue.t25" title="Not receiving the verification code?">
      If you are not receiving the email verification code, please check your spam/junk folder or go "back" to change
      your email address.
    </Message>
  );
}
