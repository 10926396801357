import React from 'react';
import { Modal } from 'components/modal/Modal';
import { Button, Container, Flex, Text, Link, List } from 'basis';
import { OverviewSources } from '_config';
import { getRatesAndFees } from 'featureToggles';
import { getTerms } from 'utils';

export const AfsFeaturesAndBenefits = ({ isOpen, onClose, getLinkWithAnalytics, trackEventSender }) => {
  const { cashAnnualRate, monthlyFee, expiredPromotionalPurchaseRate } = getRatesAndFees();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container padding="6" padding-md="8">
        <Container padding="0 0 4">
          <Flex placeItems="left center">
            <Text textStyle="heading6" textStyle-md="heading4">
              Credit card features and benefits
            </Text>
          </Flex>
        </Container>
        <Container>
          Please ensure the{' '}
          <Link
            href={getLinkWithAnalytics(OverviewSources.AU.latitude_afs_card)}
            newTab
            onClick={() =>
              trackEventSender({
                location: 'landing',
                label: OverviewSources.AU.latitude_afs_card.label,
              })
            }
          >
            {OverviewSources.AU.latitude_afs_card.label}
          </Link>{' '}
          credit card is right for you.
        </Container>
        <Container padding="6 0" margin="4 0 0 0">
          <List>
            <List.Item>
              Interest Free offers available at Apple. Visit{' '}
              <Link
                newTab
                href={getLinkWithAnalytics(OverviewSources.AU.afs_apple)}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.afs_apple.label,
                  })
                }
              >
                {OverviewSources.AU.afs_apple.label}
              </Link>{' '}
              for latest 0% offers & how to shop.
            </List.Item>
            <List.Item>
              Interest Rates - {cashAnnualRate?.toFixed(2)}% p.a. on cash advances and{' '}
              {expiredPromotionalPurchaseRate?.toFixed(2)}% p.a on expired Interest Free Payment Plans
            </List.Item>
            <List.Item>
              Account Fee - ${monthlyFee.toFixed(2)} {getTerms('monthlyCreditCardFee')} (see other{' '}
              <Link
                newTab
                href={getLinkWithAnalytics({
                  label: 'Rates and Fees',
                  url: OverviewSources.AU.afs_rates_fees.url,
                })}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: 'Rates and Fees',
                  })
                }
              >
                Rates and Fees
              </Link>{' '}
              here)
            </List.Item>
            <List.Item>
              Safe and secure - Shop knowing your card and account are protected by stringent security systems
            </List.Item>
            <List.Item>
              Before making a decision, please read the{' '}
              <Link
                newTab
                href={getLinkWithAnalytics(OverviewSources.AU.afs_key_facts_sheet)}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.afs_key_facts_sheet.label,
                  })
                }
              >
                {OverviewSources.AU.afs_key_facts_sheet.label}
              </Link>{' '}
              and{' '}
              <Link
                newTab
                href={getLinkWithAnalytics(OverviewSources.AU.afs_conditions_of_use)}
                onClick={() =>
                  trackEventSender({
                    location: 'landing',
                    label: OverviewSources.AU.afs_conditions_of_use.label,
                  })
                }
              >
                {OverviewSources.AU.afs_conditions_of_use.label}
              </Link>{' '}
              to ensure you're aware of all the fees, charges and interest rates.
            </List.Item>
          </List>
        </Container>

        <Flex placeItems="center">
          <Container padding="2">
            <Button width="160px" onClick={onClose}>
              Close
            </Button>
          </Container>
        </Flex>
      </Container>
    </Modal>
  );
};
