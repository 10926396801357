import styled from '@emotion/styled';
import React from 'react';
import { RadioGroup } from 'basis';
import { useTheme } from '@emotion/react';

export const ButtonToggleWrapper = styled.div`
  ${props => `
    div[role="radiogroup"] {
      border-radius: 4px;
      border: 1px solid ${props.theme?.primaryButtonColor};
      width: 350px;
      & > div {
        grid-column-gap: 0;
      }

      & > div > div:first-child {
        div[role="radio"] {
          border-right: 1px solid ${props.theme?.primaryButtonColor};
        }
      }

      ${`@media (max-width: ${props.theme.screenSize?.md})`} {
        width: 100%;
      }
    }

    div[role="radiogroup"]:has(+ div) {
      border: 1px solid ${props.theme?.colors?.conditional?.negative?.graphics ?? '#cf000f'};
      & > div > div:first-child {
        div[role="radio"] {
          border-right: 1px solid ${props.theme?.colors?.conditional?.negative?.graphics ?? '#cf000f'};
        }
      }
    }

    div[role="radiogroup"] + div {
      border: 0;
    }

    &:has(input:checked) {
      & div[role="radio"] {
        border-right: 0 !important;
      }
    }

    label {
      height: auto;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: white;
      margin: 5px;
      color: ${props.theme.primaryButtonColor};
      border-radius: 4px;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      padding: 10px 5px;

      &:hover {
        background-color: #E6EFFD;
      }
    }

    input:checked + label {
      background: ${props.theme.primaryButtonColor};
      color: white;

      &:hover {
        background-color: #004AB6;
      }
    }

    svg {
      display: none;
    }
  `}
`;

export const ButtonToggle = ({ ...props }) => {
  const theme = useTheme();
  return (
    <ButtonToggleWrapper theme={theme}>
      <RadioGroup {...props} />
    </ButtonToggleWrapper>
  );
};
