import React, { useCallback } from 'react';
import { Container, Message, Text, Icon, Grid, List, Link } from 'basis';
import { LayoutPage, LayoutContent, SaveAndCompleteLaterButton } from 'components';
import styled from '@emotion/styled';
import { useStoreValue } from 'store';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { STEP_AUS_POST, STEP_VERIFY_INSTORE } from '_config';
import { device } from 'media';
import { BadgeWithIcon } from 'components/badge';
import { TimeIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/react';
import { DividerWithText } from 'components/divider';
import { TextButton } from 'components/button';
import { useHistory } from 'react-router-dom';
import { AusPostAction } from '../aus-post/AusPostAction';
import { isCreditLine, isGO, isGemAU, isGemAUWithMerchantApple } from '../../utils';

const IconWrapper = styled.span`
  position: relative;
  top: 5px;
  svg {
    width: 24px;
    height: 24px;
    display: inline;
    margin: 0 8px;
  }
`;

const ContentWrapper = styled.div`
  li::before {
    background-color: black;
    width: 0.3em;
    height: 0.3em;
  }

  hr {
    margin: 40px 0;
  }

  img {
    height: auto;
    width: 120px;
  }

  div[data-testid='inner-container'] {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  }

  @media ${device.mobileOrSmaller} {
    button {
      width: 100%;
    }
  }
`;

export const VerifyIdentityInstoreOrAusPost = () => {
  const [store, updateStore] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(store);
  const isGOOrGemAu = isGO() || isGemAU();
  const storeFinderLabel = isCreditLine() || isGemAUWithMerchantApple(store) ? 'Find Apple Store' : 'Find a store';
  const history = useHistory();

  const handleClickVerifyAtAusPost = useCallback(() => {
    trackEventSender({
      category: 'application',
      action: 'application-navigation',
      location: window.location.pathname,
      label: 'Verify at Australia Post',
    });
    const nextStep = STEP_AUS_POST;
    updateStore({
      activeStep: nextStep,
    });
    history.push(nextStep);
  }, [history, trackEventSender, updateStore]);

  return (
    <LayoutPage hideBackButton>
      <LayoutContent rowsGap="0">
        <ContentWrapper>
          <Container padding="0 0 6 0" padding-md="0 0 8 0">
            <Message testId="pink-message" severity="info-or-minor" bg="secondary.pink.t30">
              <strong>Unfortunately, we weren't able to verify your identity.</strong>
            </Message>

            <Text textStyle="heading4" as="h1" margin="6 0">
              <strong>Steps to verify your identity in-store</strong>
            </Text>

            <Text as="p" testId="application-ref">
              Application reference number: <strong>{store.applicationRef}</strong>
            </Text>

            <Text as="p" margin="6 0">
              {isGemAUWithMerchantApple(store)
                ? 'Follow the steps below, and take the required documentation in-store to your nearest Apple Store or alternatively to Australia Post'
                : isCreditLine()
                ? 'your nearest Apple Store'
                : isGO()
                ? 'your nearest Harvey Norman'
                : 'one of our merchant partners'}
              .
            </Text>
          </Container>
          <Container bg="grey.t03" padding="4" padding-md="10">
            <Container bg="white" padding="8" testId="inner-container">
              <Container>
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  justifyContent="space-between"
                  mb={1}
                  marginBottom={24}
                >
                  <Text textStyle="heading5">Verify at an Apple Store</Text>
                  <BadgeWithIcon badge="Fast response" badgeColor="#E8F4FF" icon={TimeIcon} />
                </Flex>

                <AusPostAction order="1">
                  Take your identification documents {isGO() ? 'to a Harvey Norman' : 'in-store'}
                </AusPostAction>
                <Text as="p" margin="0 0 0 9">
                  Find your nearest{' '}
                  {isCreditLine() || isGemAUWithMerchantApple(store)
                    ? 'Apple Store'
                    : isGO()
                    ? 'Harvey Norman'
                    : 'Latitude merchant partner'}{' '}
                  and verify your identity.
                </Text>
                <Grid margin="8 0 0 0">
                  <Link
                    newTab
                    appearance="primary-button"
                    testId="find-instore-form-btn"
                    href={store.applyAndBuy?.storeFinderUrl}
                    onClick={() => {
                      trackEventSender({
                        action: 'external-link',
                        location: STEP_VERIFY_INSTORE,
                        label: storeFinderLabel,
                      });
                    }}
                  >
                    <span>{storeFinderLabel}</span>
                    <IconWrapper>
                      <Icon name="external-link" color="white" />
                    </IconWrapper>
                  </Link>
                </Grid>
              </Container>
              <Container margin="8 0">
                <AusPostAction order="2">Identification documents must be originals and current</AusPostAction>
                <Text as="p" margin="0 0 8 9">
                  You <strong>MUST supply ONE primary photographic document</strong> from the list below.
                </Text>
              </Container>
              <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
                <List>
                  <List.Item>
                    If your <strong>primary photographic document</strong> contains your current residential address -
                    then no further identification is required.
                  </List.Item>
                  <List.Item>
                    <Container margin="6 0 0 0">
                      If your primary photographic document <strong>DOES NOT</strong> contain your current residential{' '}
                      address, then you must {isGOOrGemAu ? '' : 'also '}
                      <strong>supply ONE secondary document that contains your current residential address.</strong>.
                    </Container>
                  </List.Item>
                </List>
              </Message>
              <Container margin="6 0">
                <Text margin="3 0">
                  <strong>Accepted Primary Photographic ID Documents</strong>
                </Text>
                <List>
                  <List.Item>Australian Driver Licence (current)</List.Item>
                  <List.Item>Australian Learners Permit (current)</List.Item>
                  <List.Item>Australian Passport (not expired more than 2 years)</List.Item>
                  <List.Item>Foreign Passport (current only)</List.Item>
                  <List.Item>Proof of Age card/NSW Photo Card (current and government issued)</List.Item>
                </List>
              </Container>
              <Container margin="6 0">
                <Text margin="3 0">
                  <strong>Accepted Secondary ID Documents</strong>
                </Text>
                <List>
                  <List.Item>Utility Bill or Council Rates Notice (less than 3 months old)</List.Item>
                  <List.Item>Taxation notice or Centrelink statement (less than 12 months old)</List.Item>
                </List>
              </Container>
            </Container>
            <DividerWithText data-content="or" />
            <Container bg="white" padding="8" testId="inner-container">
              <Container>
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  alignItems={{ base: 'flex-start', md: 'center' }}
                  justifyContent="space-between"
                  mb={1}
                  marginBottom={24}
                >
                  <Text textStyle="heading5">Verify at Australia Post </Text>
                  <BadgeWithIcon badge="5-10 business days" badgeColor="#E5E5E5" icon={TimeIcon} />
                </Flex>
                <List>
                  <List.Item>
                    This can take up to{' '}
                    <span style={{ fontWeight: 500, textDecoration: 'underline' }}>5-10 business days</span> to process.
                  </List.Item>
                  <List.Item>Take the required documentation and form to an Australia Post office.</List.Item>
                </List>

                <Box marginY="28">
                  <TextButton
                    style={{
                      fontFamily: 'Roboto',
                      fontWeight: 500,
                      fontSize: 18,
                      textAlign: 'center',
                      padding: '12px 24px',
                    }}
                    data-testid="verify-at-aus-post-btn"
                    onClick={handleClickVerifyAtAusPost}
                  >
                    Verify your identity at Australia Post
                  </TextButton>
                </Box>
              </Container>
            </Container>
          </Container>
          <Container margin="8 0">
            <SaveAndCompleteLaterButton />
          </Container>
        </ContentWrapper>
      </LayoutContent>
    </LayoutPage>
  );
};
