import React, { useMemo } from 'react';
import { LayoutPage, Errors } from 'components';
import { useStoreValue } from 'store';
import { getProductInfo, initFormConfig, isAU, submitHandler, isPreSubmission } from 'utils';
import { config, STEP_PROCESSING_ACCEPTANCE } from '_config';
import {
  useFormInitialValues,
  useAcceptPcaContract,
  useDataLayerAndAnalyticsLinks,
  useErrorTrackingAndUpdateStore,
} from 'hooks';
import { QUOTE_INITIAL, QUOTE_DECLINE, REFERRED, DECLINED, PROCESSING_ACCEPTANCE } from 'store/constants';
import { useHistory } from 'react-router-dom';
import gte from 'lodash/gte';
import { isFeatureOn } from 'featureToggles';
import { PostBureau } from './PostBureau';
import { PreBureau } from './PreBureau';
import { QuoteDeclined } from './QuoteDeclined';
import { DclCa } from './DclCa.au';
import { DclDownsell } from './DclDownsell.au';
import { DclReferred } from './DclReferred.au';
import { CreditLimitAcceptance } from './CreditLimitAcceptance';

const FORM_ID = 'confirmCreditLimit';

export const ConfirmCreditLimit = () => {
  const [storeState] = useStoreValue();
  const initialValues = useFormInitialValues(FORM_ID);
  const { creditLimitApproved, applicationStatus } = storeState;

  const formConfig = useMemo(() => {
    return initFormConfig(config.formConfigs[FORM_ID](storeState.creditLimitApproved));
  }, [storeState.creditLimitApproved]);

  if (isAU()) {
    return ConfirmCreditLimitAU(creditLimitApproved, applicationStatus);
  }
  return ConfirmCreditLimitNZ(creditLimitApproved, applicationStatus, initialValues, formConfig);
};

const ConfirmCreditLimitNZ = (creditLimitApproved, applicationStatus, initialValues, formConfig) => {
  if (isFeatureOn('creditLimitAcceptance') && isPreSubmission(applicationStatus)) {
    return <CreditLimitAcceptance />;
  }

  // https://latitudefs.atlassian.net/browse/OBS-8879 PCA opa. Keep the blowing logic for in-flight application and will remove afterwards.
  if (applicationStatus === QUOTE_DECLINE) {
    return <QuoteDeclined FORM_ID={FORM_ID} initialValues={initialValues} formConfig={formConfig} />;
  }
  if (applicationStatus === QUOTE_INITIAL && creditLimitApproved) {
    return <PostBureau FORM_ID={FORM_ID} initialValues={initialValues} formConfig={formConfig} />;
  }
  return <PreBureau FORM_ID={FORM_ID} initialValues={initialValues} formConfig={formConfig} />;
};

const ConfirmCreditLimitAU = (creditLimitApproved, applicationStatus) => {
  const [storeState, updateStore] = useStoreValue();
  const history = useHistory();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Submit');
  const status = useMemo(() => {
    return applicationStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitSuccess = () => {
    updateStore({
      applicationErrors: null,
      activeStep: STEP_PROCESSING_ACCEPTANCE,
      applicationStatus: PROCESSING_ACCEPTANCE,
    });

    history.push(STEP_PROCESSING_ACCEPTANCE);
  };

  const { submit, loading: submitting } = useAcceptPcaContract({
    applicationId: storeState.applicationId,
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const submitValues = () => {
    return {
      acceptedCreditLimit: storeState.creditLimitAccepted || storeState.creditLimitApproved,
      accepted: true,
    };
  };

  const onSubmit = ({ values, errors }) => {
    const handle = () => {
      const newAcquisition = {
        ...storeState.acquisition,
        acceptance: { ...values },
      };
      updateStore({ acquisition: newAcquisition });

      trackEventSender(
        {
          category: 'application',
          action: 'application-submitted',
          location: 'credit-limit',
          label: 'Accept credit card limit',
          value: storeState.creditLimitAccepted || storeState.creditLimitApproved,
        },
        { applicationStatus: PROCESSING_ACCEPTANCE },
      );

      submit(submitValues());
    };
    submitHandler({ submit: handle, errors });
  };

  if (storeState.applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={storeState.applicationRef} />
      </LayoutPage>
    );
  }

  const downSellCreditLimitValue = getProductInfo().minCreditLimit;
  if (
    status === QUOTE_DECLINE ||
    status === REFERRED ||
    status === DECLINED ||
    (status === QUOTE_INITIAL && !gte(creditLimitApproved, downSellCreditLimitValue))
  ) {
    return <DclReferred onSubmit={onSubmit} submitting={submitting} />;
  }
  if (status === QUOTE_INITIAL && creditLimitApproved === downSellCreditLimitValue) {
    return <DclDownsell onSubmit={onSubmit} submitting={submitting} />;
  }
  return <DclCa onSubmit={onSubmit} submitting={submitting} />;
};
