import React from 'react';
import { Container, useTheme, Text, Button } from 'basis';
import { byCard, getProductName } from 'utils/byConfig';
import {
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_LOW_RATE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
} from '_config';
import { isFeatureOn } from 'featureToggles';
import { HeaderBannerTextStyle, ProductNameWrapper, ProductSubtitle } from './LandingPageAu.styles';

const HeaderBannerTextArea = ({ openFeatureAndBenefitsModal }) => {
  const theme = useTheme();
  const buttonLabel = byCard({
    [PRODUCT_CODE_AFS]: 'View features & benefits',
    default: 'View features and benefits',
  });
  const productName = getProductName();
  const featureBenefitBtn = (
    <Button margin="0 0 8 0" variant="secondary" onClick={openFeatureAndBenefitsModal}>
      {buttonLabel}
    </Button>
  );
  return (
    <HeaderBannerTextStyle theme={theme}>
      <Container
        padding="0 4 8 4"
        padding-xs="0 6 0 5"
        padding-md="0 0 0 0"
        margin="0"
        margin-md="0 0 0 5"
        margin-lg="0 0 0 5"
        margin-xl="0 0 0 0"
        width="100%"
      >
        <ProductNameWrapper>
          {byCard({
            [PRODUCT_CODE_AU_GEM]: isFeatureOn('gemLandingAgreement')
              ? 'Thanks for choosing Latitude Gem Visa credit card'
              : 'Apply for a Latitude Gem Visa credit card',
            [PRODUCT_CODE_GO]: 'Apply for a Latitude GO Mastercard credit card',
            [PRODUCT_CODE_AFS]: 'Apply for a CreditLine credit card',
            [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: `Apply for a ${productName}`,
            [PRODUCT_CODE_LOW_RATE]: `Apply for a ${productName}`,
            default: '',
          })}
          <br />
        </ProductNameWrapper>
        <ProductSubtitle>
          <Text margin="4 0" color="white" textStyle="subtitle1">
            {byCard({
              [PRODUCT_CODE_AU_GEM]: isFeatureOn('gemLandingAgreement') ? '' : 'Shop today with more time to pay.*',
              [PRODUCT_CODE_GO]: 'Break up your bigger buys at hundreds of Australia’s biggest retailers.*',
              default: '',
            })}
          </Text>
        </ProductSubtitle>
        {byCard({
          [PRODUCT_CODE_AU_GEM]: isFeatureOn('gemLandingAgreement') ? null : featureBenefitBtn,
          default: (
            <Button margin="0 0 8 0" variant="secondary" onClick={openFeatureAndBenefitsModal}>
              {buttonLabel}
            </Button>
          ),
        })}
      </Container>
    </HeaderBannerTextStyle>
  );
};

export { HeaderBannerTextArea };
