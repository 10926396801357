import React from 'react';
import { Box, Text as ChText, Flex, VStack, ChakraProvider } from '@chakra-ui/react';
import { Text } from 'basis';
import styled from '@emotion/styled';
import { TimeIcon } from '@chakra-ui/icons';
import { byCard, isGemAUWithMerchantApple } from 'utils';
import { PRODUCT_CODE_AFS, PRODUCT_CODE_AU_GEM } from '_config';
import { MoneyDocumentsIcon, MoneyEnvelopeIcon } from 'components';
import { BadgeWithIcon } from 'components/badge';
import { useStoreValue } from 'store';

export function PoiMethodsAU({ showBankConnectOption, onClickBankConnect, onClickPayslipUpload, onClickDocUpload }) {
  const [store] = useStoreValue();

  const poiMethods = [
    {
      icon: <LfsLockIcon />,
      title: 'Online Bank Connect',
      description:
        "Allow one-time access to your bank statements. It's secure, easy and your login details are never stored.",
      badge: 'Fast response',
      badgeColor: '#e8f4ff',
      onClick: onClickBankConnect,
      shouldShow: showBankConnectOption,
      testId: 'verify_online',
    },
    {
      icon: <MoneyEnvelopeIcon size={34} />,
      title: 'Upload payslips',
      description: 'Payslips cannot be older than 60 days.',
      badge: 'Fast response',
      badgeColor: '#e8f4ff',
      onClick: onClickPayslipUpload,
      testId: 'upload_payslips',
    },
    {
      icon: <MoneyDocumentsIcon />,
      title: 'Upload bank statements / other documents',
      description:
        'Upload one or multiple documents to verify your income such as bank statements, tax returns or letters from your accountant or employer.',
      badge: byCard({
        [PRODUCT_CODE_AFS]: '1-2 business hours',
        [PRODUCT_CODE_AU_GEM]: isGemAUWithMerchantApple(store) ? '1-2 business hours' : '1-2 business days',
        default: '1-2 business days',
      }),
      badgeColor: '#E5E5E5',
      onClick: onClickDocUpload,
      testId: 'upload_your_documents',
    },
  ];

  return (
    <ChakraProvider>
      <VStack spacing="0" marginTop="24px">
        {poiMethods.map(method => method.shouldShow !== false && <PoiMethod key={method.title} {...method} />)}
      </VStack>
    </ChakraProvider>
  );
}

function PoiMethod({ title, description, badge, icon, badgeColor, onClick, testId }) {
  return (
    <MethodButton onClick={onClick} padding={{ base: '16px 0px', md: '24px' }} data-testid={testId}>
      <Flex alignItems={{ base: 'flex-start', sm: 'center' }} width="100%">
        <MethodIconWrapper padding={{ base: '4px 0px 4px 8px', md: '0px 10px 12px 0px' }}>{icon}</MethodIconWrapper>

        <Box flex="1" marginRight="6">
          <Box position="relative" mb={1}>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent="space-between"
              mb={1}
            >
              <ChText
                fontFamily="montserrat"
                fontSize="16px"
                fontWeight="500"
                flex="1"
                marginRight={4}
                marginBottom={{ base: 2, md: 0 }}
              >
                {title}
              </ChText>
              <BadgeWithIcon badge={badge} badgeColor={badgeColor} icon={TimeIcon} />
            </Flex>
          </Box>
          <Text textStyle="body2">{description}</Text>
        </Box>

        <ChevronRightIcon alignSelf="center" />
      </Flex>
    </MethodButton>
  );
}

const MethodButton = styled(Box)`
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #f7fafc;
  }

  border-bottom: 1px solid #e2e8f0;
  &:first-of-type {
    border-top: 1px solid #e2e8f0;
  }
`;

const MethodIconWrapper = styled(Box)`
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

function LfsLockIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="44" viewBox="0 0 34 44" fill="none">
      <path
        d="M6.44446 30.7299C5.06935 27.7503 3.92343 21.3328 5.06935 18.1241L3.46506 17.4365L2.08995 18.1241L0.714844 19.9577V40.8145L2.08995 43.1064H19.7372C11.4865 40.8145 6.44446 30.7299 6.44446 30.7299Z"
        fill="#A3DDFF"
      />
      <path
        d="M6.26451 17.5154V11.0063C6.32192 8.2572 7.45425 5.64011 9.41865 3.71622C11.3831 1.79232 14.0231 0.714844 16.7726 0.714844C19.5222 0.714844 22.1622 1.79232 24.1266 3.71622C26.091 5.64011 27.2233 8.2572 27.2807 11.0063V17.5154M16.8477 35.3324V29.8318M10.3661 17.6136V11.4738C10.3661 10.6311 10.5321 9.79658 10.8546 9.01797C11.177 8.23937 11.6497 7.53191 12.2456 6.936C12.8415 6.34008 13.5489 5.86737 14.3275 5.54486C15.1061 5.22235 15.9405 5.05636 16.7832 5.05636C17.626 5.05636 18.4604 5.22235 19.239 5.54486C20.0176 5.86737 20.725 6.34008 21.3209 6.936C21.9168 7.53191 22.3894 8.23937 22.7119 9.01797C23.0344 9.79658 23.2004 10.6311 23.2004 11.4738V17.6136M3.58225 17.6136H30.1081C31.627 17.6136 32.8583 18.8449 32.8583 20.3639V40.4873C32.8583 42.0062 31.627 43.2376 30.1081 43.2376H3.58225C2.06334 43.2376 0.832031 42.0062 0.832031 40.4873V20.3639C0.832031 18.8449 2.06334 17.6136 3.58225 17.6136ZM18.6843 27.9976C18.6843 29.0103 17.8634 29.8312 16.8508 29.8312C15.8382 29.8312 15.0173 29.0103 15.0173 27.9976C15.0173 26.985 15.8382 26.1641 16.8508 26.1641C17.8634 26.1641 18.6843 26.985 18.6843 27.9976Z"
        stroke="#414141"
        strokeWidth="1.42857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ChevronRightIcon({ color = '#0061EE', width = 12, height = 20, ...props }) {
  return (
    <Box {...props}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.378831 0.293044C0.769355 -0.09748 1.40252 -0.09748 1.79304 0.293044L10.7945 9.29451C11.185 9.68504 11.185 10.3182 10.7945 10.7087L1.79304 19.7102C1.40252 20.1007 0.769355 20.1007 0.378831 19.7102C-0.0116939 19.3197 -0.0116939 18.6865 0.378831 18.296L8.67319 10.0016L0.378831 1.70726C-0.0116936 1.31673 -0.0116935 0.683569 0.378831 0.293044Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}
