import React from 'react';
import { Container } from 'basis';
import 'typeface-montserrat';
import 'typeface-roboto';
import { useStoreValue } from 'store';
import { Header } from 'components/header/Header';
import { Footer } from 'components/footer/Footer';
import { ProgressStepper } from 'components/progress-stepper/ProgressStepper';
import { ProgressBar } from 'components/progress-bar/ProgressBar';
import { configByRouteMap } from '_config/_constants/configByRouteMap';
import { PageTitle } from 'components/page-title/PageTitle';
import { SessionTimeout } from 'components/session-timeout/SessionTimeout';
import { isFeatureOn } from 'featureToggles';
import { byCountry } from 'utils';
import { useSteps } from 'hooks';
import { BackNavigator } from './BackNavigator';

export function LayoutPage({
  children,
  noProgressStepper = false,
  noSessionTimeout = false,
  preHeader = null,
  headerAlign = 'left',
  headerOverride,
  hideBackButton,
  noChildrenPadding = false,
  noTitlePadding = false,
  hideTitleIfErrors = true,
}) {
  const { pathname, canGoBack, goBack } = useSteps();
  const showBackButton = hideBackButton
    ? false
    : byCountry({
        AU: true,
        NZ: isFeatureOn('saveAndResume') && isFeatureOn('showSaveAndResumeButton'),
      });
  const hasBackButton = showBackButton && canGoBack;

  const [storeState] = useStoreValue();
  const { applicationErrors } = storeState;
  const { header, progress, footer, percentage } = configByRouteMap(pathname, storeState) || {};
  const displayProgressStepper =
    progress !== 'Outcome' &&
    progress !== 'Overview' &&
    progress !== 'Comparison' &&
    !applicationErrors &&
    !noProgressStepper;
  return (
    <>
      <Container>
        {!noSessionTimeout && <SessionTimeout />}
        <Header />
        {displayProgressStepper &&
          (isFeatureOn('progressBarNudge') && percentage !== undefined ? (
            <ProgressBar percentage={percentage} />
          ) : (
            <ProgressStepper progress={progress} />
          ))}
      </Container>
      <Container padding={noChildrenPadding ? '0' : '3 0'} padding-md={noChildrenPadding ? '0' : '8 0'}>
        {preHeader}
        {hasBackButton && <BackNavigator goBack={goBack} />}
        {(headerOverride ?? header) && (!hideTitleIfErrors || !applicationErrors) && (
          <PageTitle header={headerOverride ?? header} textAlign={headerAlign} noPadding={noTitlePadding} />
        )}
        <main>{children}</main>
      </Container>
      <Footer footer={footer} />
    </>
  );
}
