import React from 'react';
import { Text, Message, Container, Stack } from 'basis';
import { APPLY_BTN_LABEL } from '_config';
import { isFeatureOn } from 'featureToggles';
import { DEV_FEATURES } from '__dev';
import { StartApplicationButton } from './StartApplicationButton';
import { Explain } from './Explain';

export const ExplainAndStart = ({
  trackEventSender,
  formTealiumLocation,
  theme,
  isAdblockCheckComplete,
  isAdblockerDetected,
  loading,
}) => {
  return (
    <>
      <Explain trackEventSender={trackEventSender} formTealiumLocation={formTealiumLocation} />
      <Stack gap="2">
        <Container margin-md="0 0 6 0">
          <Container margin-md="0 0 4 0">
            <Text textStyle="heading5" as="h4">
              <Container margin="8 0">
                {isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP && (
                  <Message severity="stop" bg="secondary.pink.t30">
                    Please turn off your adblocker for the application to work and refresh your browser once it is done.
                  </Message>
                )}
                <StartApplicationButton
                  backgroundColor={theme.newStageButtonColor}
                  disabled={
                    !isFeatureOn('launched') ||
                    (isAdblockCheckComplete && isAdblockerDetected && !DEV_FEATURES.OVERRIDE_AD_BLOCKER_STOP)
                  }
                  loading={loading}
                  theme={theme}
                >
                  {APPLY_BTN_LABEL}
                </StartApplicationButton>
              </Container>
            </Text>
          </Container>
        </Container>
      </Stack>
    </>
  );
};
