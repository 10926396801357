import React from 'react';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import styled from '@emotion/styled';
import { davidJonesTheme } from '../../../../theme';
import {
  StyledDivSpaceBetween,
  StyledULWideLeftPadding as StyledUL,
  ballotBoxWithTick,
  ballotBoxWithX,
  LightHr,
} from './Styles';

export function AuGemFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
}) {
  const {
    purchaseAnnualRate,
    cashAnnualRate,
    expiredPromotionalPurchaseRate,
    maximumPurchase1,
    maximumPurchase2,
    minimumRepaymentRate,
    adjustedClosingBalance,
    monthlyFee,
    cashAdvanceFeePercentagePerTransaction,
    cashAdvanceFeeFixed,
    internationalTransactionRate,
    lateFee,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
    balanceTransferringFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Text>
        <Container margin="0 0 0 0">
          <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
            <Grid preset="page" debug={false}>
              <Grid.Item colSpan="all">
                <Grid rowsGap={0}>
                  <Container padding="4 4" margin="0 8">
                    <div style={{ marginBottom: '40px' }}>
                      <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                        Download or Print file
                      </Link>
                    </div>
                    <Grid preset="page" colsGap={11}>
                      <Grid.Item colSpan="all">
                        <Grid rowsGap={1}>
                          <Container padding="0">
                            <div id="financial-table">
                              <Stack gap="1">
                                <Text
                                  as="h1"
                                  textStyle="heading5"
                                  textStyle-md="heading4"
                                  color={davidJonesTheme.headingColor}
                                >
                                  {productName} Contract Schedule
                                </Text>
                                <hr />
                                <Text textStyle="heading6">Offer to New {productName} Account Holders</Text>
                                <Text>
                                  Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145,
                                  as the credit provider, offers to provide you with a {productName} credit card and
                                  account on the conditions set out in the Credit Contract.
                                </Text>
                                <br />
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                <Text>
                                  <strong>Adriana Martinez</strong>
                                </Text>
                                <Text>Executive General Manager Pay, Latitude</Text>
                                <br />
                                <Text textStyle="heading5">Overview of your {productName} credit card</Text>
                                <hr />
                                <Text>
                                  As per the Latitude Credit Card General Terms and Conditions ('General Terms and
                                  Conditions'), the following features are available through your Latitude Gem Visa
                                  credit card:
                                </Text>
                                <LightHr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={0.2} style={{ fontSize: '26px' }}>
                                      {ballotBoxWithTick}
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>Interest Free Payment Plans</StyledTableCell>
                                    <StyledTableCell flex={4}>
                                      <Text>
                                        Available on general purchases made under payment plan offers at participating
                                        retailers:
                                        <StyledUL>
                                          <li>Equal Monthly Payment Plans</li>
                                          <li>Minimum Monthly Payment Plans</li>
                                          <li>Deferred Payment Plans</li>
                                        </StyledUL>
                                      </Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={0.2} style={{ fontSize: '26px' }}>
                                      {ballotBoxWithX}
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      Instalment Offer Plans (interest bearing)
                                    </StyledTableCell>
                                    <StyledTableCell flex={4}>Not available</StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={0.2} style={{ fontSize: '26px' }}>
                                      {ballotBoxWithTick}
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>International transactions</StyledTableCell>
                                    <StyledTableCell flex={4}>Available</StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Financial table</Text>
                                <hr />
                                <Text>
                                  The details below are correct as at the disclosure date. The financial table does not
                                  contain all of the pre- contractual information we are required by law to give you.
                                  Further terms and conditions (and an explanation of some of the words used in the
                                  financial table) are in the General Terms and Conditions. You must read this Contract
                                  Schedule together with the General Terms and Conditions.
                                </Text>
                                <br />
                                <StyledTable style={{ textAlign: 'center' }}>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Account number</StyledTableCell>
                                    <StyledTableCell flex={1.3}>Disclosure date</StyledTableCell>
                                    <StyledTableCell flex={1.3}>Credit limit</StyledTableCell>
                                    <StyledTableCell flex={1.3}>Credit sub-limit</StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>
                                      <data-hj-suppress>
                                        <Text>{accountNumber}</Text>
                                      </data-hj-suppress>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      <Text>{statementDate}</Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      <data-hj-suppress>
                                        <Text>${creditLimit}</Text>
                                      </data-hj-suppress>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      <Text>N/A</Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading6">Cash limit (refer to your statement)</Text>
                                <hr />
                                <Text>
                                  A cash limit forms part of your credit limit and may be withdrawn in cash or cash
                                  equivalent transactions. In each statement we give you, we may notify you of any cash
                                  limit we impose, withdraw or change from time to time.
                                </Text>
                                <br />
                                <Text textStyle="heading5">Annual percentage rates</Text>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Purchase Rate - applies to the general purchase balance</Text>
                                  <Text>{purchaseAnnualRate}% p.a.</Text>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Cash Advance Rate - applies to the cash advance balance</Text>
                                  <Text>{cashAnnualRate}% p.a.</Text>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Expired Plan Rate - applies to expired Plan balance</Text>
                                  <Text>{expiredPromotionalPurchaseRate}% p.a.</Text>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Balance Transfer Rate - applies to balance transfer offers</Text>
                                  <Text>
                                    The Cash Advance Rate applies, unless a specific balance transfer rate is
                                    subsequently offered
                                  </Text>
                                </StyledDivSpaceBetween>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>Instalment Offer Rate - applies to instalment offers</Text>
                                  <Text>
                                    The Purchase Rate applies unless a specific instalment offer rate is subsequently
                                    offered
                                  </Text>
                                </StyledDivSpaceBetween>
                                <br />
                                <Text textStyle="heading5">Interest free periods</Text>
                                <hr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1}>Up to 55 days</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <ol style={{ marginLeft: '-24px', marginTop: '-4px' }}>
                                        <li>
                                          General purchases of goods and services (not cash advances) of up to $
                                          {maximumPurchase1} in the one transaction, may (subject to clause 2.), be
                                          interest free up to 55 days from the transaction date.
                                        </li>
                                        <li>
                                          We do not charge interest on the general purchase balance for a statement
                                          period if during the previous statement period:
                                          <ol type="a" style={{ marginLeft: '-24px' }}>
                                            <li>
                                              you pay by the due date the modified closing balance, for the statement of
                                              account issued at the start of that statement period; or
                                            </li>
                                            <li>
                                              that statement of account did not require you to make a minimum monthly
                                              payment.
                                            </li>
                                          </ol>
                                          If you do not meet the requirements under this clause for a statement period,
                                          interest will be calculated using the Purchase Rate and charged on any
                                          outstanding amount of the general purchase balance during that statement
                                          period.
                                        </li>
                                      </ol>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1}>6 months</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      General purchases of goods and services (not cash advances) of ${maximumPurchase2}{' '}
                                      or more in the one transaction, will be interest free for 6 months subject to the
                                      terms of a Minimum Monthly Payment Plan.
                                      <br />
                                      <br />
                                      We will charge interest calculated using the Purchase Rate on any outstanding
                                      amount after the 6-month period.
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1}>6-60 months</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        Available on general purchases made under payment plan offers at participating
                                        retailers subject to the following types of Interest Free Payment Plans:
                                        <br />
                                        <StyledUL>
                                          <li>
                                            Equal Monthly Payment Plans - see clause 10.8 of the General Terms and
                                            Conditions.
                                          </li>
                                          <li>
                                            Minimum Monthly Payment Plans - see clause 10.10 of the General Terms and
                                            Conditions.
                                          </li>
                                          <li>
                                            Deferred Payment Plans - see clause 10.12 of the General Terms and
                                            Conditions.
                                          </li>
                                        </StyledUL>
                                      </Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Payments explained</Text>
                                <hr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Minimum monthly payment</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        The minimum monthly payment for a statement period is an amount equal to the sum
                                        of:
                                      </Text>
                                      <StyledUL>
                                        <li>
                                          any instalment payments due in that period under any unexpired Instalment
                                          Plans;
                                        </li>
                                        <li>
                                          the greater of ${adjustedClosingBalance} or {minimumRepaymentRate}% of the
                                          closing balance, shown for the statement period on your statement of account:
                                          <ul>
                                            <li>
                                              including the balance of Minimum Monthly Payment Plans, balance transfer
                                              offers or any expired payment plans;
                                            </li>
                                            <li>
                                              but excluding any outstanding amounts on any Instalment Plans or any
                                              Deferred Payment Plans that have not expired on or before the due date.
                                            </li>
                                          </ul>
                                        </li>
                                      </StyledUL>
                                      <Text>
                                        If your outstanding balance is less than $25, you must pay that balance.
                                      </Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Modified closing balance</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        The modified closing balance is the amount you need to pay by the due date to
                                        qualify for interest free days on general purchases.
                                      </Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Fees and charges</Text>
                                <hr />
                                <StyledDivSpaceBetween>
                                  <Text>A credit card fee will be charged monthly.</Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>${monthlyFee}</Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    A cash advance fee will be charged on each occasion on which you obtain a cash
                                    advance or make a cash equivalent transaction.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>
                                      Greater of ${cashAdvanceFeeFixed} or ${cashAdvanceFeePercentagePerTransaction}% of
                                      the cash advance amount
                                    </Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    An international transaction fee of a specific percentage of the amount of the
                                    international transaction (as converted to Australian dollars if not originally in
                                    Australian dollars) will be charged when we process each international transaction.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>
                                      {internationalTransactionRate.toFixed(2)}% of the Australian dollar value of the
                                      international transaction
                                    </Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    A balance transfer fee will be charged if you take up a balance transfer offer.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>
                                      Up to {balanceTransferringFee}% of balance transfer amount or in accordance with
                                      the specific balance transfer offer
                                    </Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    A late fee will be charged in each statement period when any amount due and payable
                                    to us is not paid in full by the due date.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>${lateFee.toFixed(2)}</Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    A paper statement fee will be charged for each paper statement of account sent to
                                    you. This fee will not be charged if you consent to receiving your statements
                                    electronically.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>${paperStatementFee.toFixed(2)}</Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    A payment handling fee will be charged each time you make a payment to your account,
                                    except that no fee is payable for payments made by mail, by direct debit or via the
                                    Latitude Service Centre.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>
                                      ${ausPostHandlingFee} (in person)
                                      <br />${onlinePaymentHandlingFee} (online)
                                    </Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <StyledDivSpaceBetween>
                                  <Text>
                                    A credit balance processing fee will be charged to your account when your account is
                                    closed with a credit balance of less than $20; unless an instruction is provided to
                                    us on how to return the credit balance to you.
                                  </Text>
                                  <div style={{ textAlign: 'right' }}>
                                    <Text>The fee amount will be the lesser of your balance or $19.99</Text>
                                  </div>
                                </StyledDivSpaceBetween>
                                <LightHr />
                                <Text>
                                  These fees and charges will be charged and debited to your account and will form part
                                  of the closing balance which is used to calculate your minimum monthly payments.
                                </Text>
                                <br />
                                <Text textStyle="heading5">Changes to the Credit Contract</Text>
                                <hr />
                                <Text>
                                  Subject to applicable law, we may change the variable annual percentage rate(s),
                                  maximum interest free period, the amount, frequency or time for payment or method of
                                  calculation of a fee or charge or minimum monthly payment, reduce the credit limit, or
                                  impose new fees or charges without your consent where we have determined it is
                                  reasonably necessary to protect our legitimate interests. You will be notified of
                                  changes or new fees or charges in the manner set out in the General Terms and
                                  Conditions. If you are not happy with any changes made to your credit contract, you
                                  may close your credit contract in accordance with clause 28.6 of the General Terms and
                                  Conditions.
                                </Text>
                                <br />
                                <Text textStyle="heading5">Commission</Text>
                                <hr />
                                <Text>
                                  An unascertainable commission may be payable for the introduction of this credit
                                  business to any merchant and related entity of a merchant which is involved in the
                                  introduction.
                                </Text>
                              </Stack>
                            </div>
                          </Container>
                        </Grid>
                      </Grid.Item>
                    </Grid>
                  </Container>
                </Grid>
              </Grid.Item>
            </Grid>
          </Container>
        </Container>
      </Text>
    </LayoutPage>
  );
}

const StyledTable = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const StyledTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledTableCell = styled.div`
  flex: ${props => props.flex || 1};
  padding: 0 8px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;
