import React from 'react';
import styled from '@emotion/styled';
import { Container, Text, Link } from 'basis';
import { getFeatureValue } from 'featureToggles';
import { CAMPAIGN_BANNER } from 'featureToggles/defaultValues';
import { config } from '_config';
import { useDataLayerAndAnalyticsLinks } from 'hooks';
import { useStoreValue } from 'store';

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerContainer = styled.div`
  padding: 0 12px 0 20px;
  margin: 16px;
  border-left: 12px solid #6e6863;
  & > div[data-testid='campaign-title'] p {
    font-weight: 600;
    font-family: 'montserrat', 'SemiBold';
  }

  & > div[data-testid='campaign-content'] {
    font-size: 14px;
  }
  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      margin: 16px 0 16px 44px;
      padding: 0 12px 0 24px;
      width: 1220px;
      & > div[data-testid='campaign-content'] {
        display: flex;
        margin-top: 6px;
      }
    }
  `}
`;

export function CampaignBanner() {
  const campaignBanner = getFeatureValue(CAMPAIGN_BANNER);

  const [storeState] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);

  if (!campaignBanner || !campaignBanner[config.productCode]) {
    return null;
  }
  const campaign = campaignBanner[config.productCode];

  return (
    <Banner>
      <BannerContainer>
        <Container testId="campaign-title">
          <Text>{campaign.title}</Text>
        </Container>
        <Container margin="3 0 1 0" testId="campaign-content">
          <Text textStyle="body2">{campaign.content}</Text>
          <Container margin="3 0 0 0" margin-lg="0 0 0 2">
            <Link
              href={campaign.tncLink}
              newTab
              onClick={() => {
                trackEventSender({
                  category: 'text-link',
                  action: 'external-link',
                  location: 'campaign-banner',
                  label: 'Terms & Conditions',
                });
              }}
            >
              Terms & Conditions
            </Link>{' '}
            apply.
          </Container>
        </Container>
      </BannerContainer>
    </Banner>
  );
}
