import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutPage, Errors } from 'components';
import { useWaitAndRedirect, useEnv, useCountdownTimer } from 'hooks';
import { Container, Text, LoadingIcon, Flex, Stack } from 'basis';
import { useStoreValue } from 'store';
import { PROCESSING_TIMEOUT } from 'store/constants';
import { isFeatureOn } from 'featureToggles';
import { byCountry } from 'utils';
import { useTheme } from '@emotion/react';

const useClearableCountdown = (updateStore, hasError) => {
  const history = useHistory();
  const getEnv = useEnv();

  const redirectToTimeout = () => {
    updateStore({
      activeStep: '/outcome-timeout',
      applicationStatusReason: PROCESSING_TIMEOUT,
    });
    history.push('/outcome');
  };

  const countdown = useCountdownTimer(
    getEnv(
      byCountry({
        NZ: 'REACT_APP_APPLICATION_PROCESS_TIME_IN_SEC',
        AU: 'REACT_APP_AU_APPLICATION_PROCESS_TIME_IN_SEC',
      }),
    ),
    redirectToTimeout,
  );
  const clearCountdown = countdown[2];

  useEffect(() => {
    hasError && clearCountdown && clearCountdown();
  }, [clearCountdown, hasError]);
};

export const ProcessingLoanInformation = () => {
  const [storeState, updateStore] = useStoreValue();
  const { applicationId, applicationRef, applicationErrors } = storeState;
  const { retry, loading } = useWaitAndRedirect({
    applicationId,
    testId: 'longPollingWhileProcessingLoanInformation',
  });
  const theme = useTheme();
  const noProgressStepper = isFeatureOn('progressBarNudge');

  useClearableCountdown(updateStore, !!applicationErrors);

  if (applicationErrors) {
    return (
      <LayoutPage>
        <Errors applicationRef={applicationRef} retry={retry} retrying={loading} />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noProgressStepper={noProgressStepper}>
      <Container testId="processingIDV" height="400" padding="6" hasBreakpointWidth>
        <Flex height="100%" placeItems="center">
          <Stack gap="8">
            <Container textAlign="center">
              <LoadingIcon size="large" color={theme.spinnerColor} />
            </Container>
            <Text textStyle="heading4" color={theme.headingColor} align="center">
              We’re currently processing your loan information
            </Text>
            <Text as="p" textStyle="subtitle2" align="center">
              Please do not refresh your screen as this may take a couple of minutes
            </Text>
          </Stack>
        </Flex>
      </Container>
    </LayoutPage>
  );
};
